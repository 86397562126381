import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { UserCreateValidation } from "../../../utils/validations/UserCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import {REQUEST_METHOD} from "../../../utils/Constants";

  const ViewUser = () => {
        const defaultForm = {
                firstName: "",
                lastName: "",
                email: "",
                username: "",
                designationId: "",
        };
        const location = useLocation();
        const uuid = location?.state?.uuid;

        const [form, setForm] = useState(defaultForm);
        const [staffPrivileges,setStaffPrivileges] = useState([]);
        const [designations,setDesignations] = useState([]);
        const [permissions,setPermissions] = useState([]);
        
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const {httpRequest}  = useAxios();

        const getInitializationData = async () =>{
            await httpRequest('staff/edit',REQUEST_METHOD.POST,{uuid})
                    .then((data) =>{
                        if(data){
                            setPermissions(data.permissions);
                            setDesignations(data.designations);
                            const userPrivileges = data?.user?.privileges?.rights;
                            const userObject = data.user;
                            delete userObject.privileges;
                            setForm(userObject);
                            setStaffPrivileges(userPrivileges);
                        }
            }); 
        }

        useEffect(() => {
                getInitializationData();
        },[]);
        
        const amIChecked = (id) =>{ return staffPrivileges.includes(id)}

	
	return <AuthorizedAreas permission="CAN_SUB_MENU_ADMINISTRATION_USER_MANAGE">
            <div className="card-body px-0 pt-0 pb-2">

                  <form>
                    <div style={{ padding: 20 }}>
                        <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-github">
                                <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                                <span className="btn-inner--text">GO BACK</span>
                            </button>
                      <div className="card mt-4" id="basic-info">
                        <div className="card-header">
                          <h5>Staff Information</h5>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-6">
                              <div className="input-group input-group-static">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  readOnly={true}
                                  className="form-control"
                                  value={form.firstName}
                                />
                              </div>
                            </div>
                             <div className="col-6">
                              <div className="input-group input-group-static">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  className="form-control"
                                  value={form.lastName}
                                  readOnly={true}
                                />
                              </div>
                           </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-6">
                              <div className="input-group input-group-static">
                                <label>Email</label>
                                <input
                                  autoComplete="off"
                                  type="email"
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  value={form.email}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="input-group input-group-static">
                                <label>Username</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="username"
                                  name="username"
                                  className="form-control"
                                  value={form.username}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                        </div>

                          <div className="row mt-4">
                            <div className="col-12">
                              <div className="input-group input-group-static">
                                <label htmlFor="designationId">Designation</label>
                                <select
                                  className="form-control"
                                  name="designationId"
                                  id="designationId"
                                  autoComplete="off"
                                  defaultValue={""}
                                  value={form.designationId}
                                  readOnly={true}
                                >
                                  { designations?.length && designations.map((e) => (
                                    <option value={e.designationID}>{e.designationName}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                          <h6 className="roleNamePermissionShow">Permissions
                            <span className="roleNamePermission text-primary" />
                          </h6>
                          <div className="row mt-4 permissions_here">
                            {permissions?.length && permissions.map((c) => (
                                <div key={c.permissionID} className="col-sm-12 col-md-6 col-lg-12">
                                    <div className="form-check form-switch">
                                        <input value={c.permissionID}
                                        readOnly={true}
                                        checked={amIChecked(c.permissionID)}
                                        id={`idHere${c.permissionID}`} className="form-check-input" name="courses[]" type="checkbox" />
                                        <label htmlFor={`idHere${c.permissionID}`} className="form-check-label">{c.permissionName}</label>
                                    </div>
                                </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
		</AuthorizedAreas>;
}

export default ViewUser