import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { SUDepartmentCreateValidation } from "../../../utils/validations/SUDepartmentCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const EditSUGroup = () => {
        
        const location = useLocation();
        const id = location?.state?.id;
        const name = location?.state?.name;

        const defaultForm = { departmentId: id,departmentName: name};

        const [form, setForm] = useState(defaultForm);
        
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const {dispatchNotification} = useGlobalNotification();
        const {httpRequest}  = useAxios();

        useEffect(() => {
        },[]);

        const handleChange = (e) => {
                const name = e.target.name;
                const value = e.target.value;
                setForm(prev=>({ ...prev, [name]: value })); 
        };

        const handleSubmit = async (e) => {
                e.preventDefault();
                //validation
            SUDepartmentCreateValidation.validate(form, { abortEarly: false }).then(async (values)=>{
                    await httpRequest('accident_reports/manage/su_department/save/edit','PATCH',form)
                    .then((data) =>{
                        if(data){
                            dispatchNotification('success',"Operation completed successfully");
                            setTimeout(()=>{navigate(-1);},1000);
                        }
                    });   
                }).catch(err=>{
                    let message = "<p>You have the following errors:</p>";
                    message  += err.errors.join("<br/>");
                    dispatchNotification('error',message);
                });
            };
            
	
	return <AuthorizedAreas permission="CAN_ACCIDENT_REPORT_EDIT_SU_DEPARTMENTS">
	<div className="card-body px-0 pt-0 pb-2">		
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
				<button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-github">
						<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
						<span className="btn-inner--text">GO BACK</span>
					</button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h5>SU Department Information</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="departmentName">Department Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="departmentName"
                          id="departmentName"
                          className="form-control"
                          value={form.departmentName}
						  onChange={(e)=>handleChange(e)}
                        />
                      </div>
                    </div>
                </div>


                 <button type="submit" className="mt-5 btn btn-icon btn-github btn-lg w-100">
                  <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                  <span className="btn-inner--text">SAVE EDITS</span>
                </button>
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default EditSUGroup