import React, { useState } from "react";

const TemporaryPassword = ({ password }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(password)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000); // Reset copied state after 2 seconds
            })
            .catch((err) => {
                console.error("Failed to copy password: ", err);
            });
    };

    return (
        <div className="mt-3">
            <div className="card shadow-lg">
                <div className="input-group">
                    <input className="form-control text-bold text-2xl" value={password} readOnly />
                        <button className="btn btn-github mb-0" id="copyButton" data-bs-toggle="tooltip"
                                onClick={copyToClipboard}
                                data-bs-placement="top" title="Copy">
                            <i className={`bi ${isCopied ? "bi-check" : "bi-clipboard"}`}></i> {isCopied ? "Copied":"Copy"}
                        </button>
                </div>
            </div>
        </div>
    );
};

export default TemporaryPassword;