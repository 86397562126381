export const APP_NAME = "UoNSU DMS";
export const REQUEST_METHOD = {
    POST: "POST",
    PATCH: "PATCH",
    PUT: "PUT",
    DELETE: "DELETE",
    GET: "GET"
};

export const MAX_LOGIN_ATTEMPT = 5;
export const MIN_PASSWORD_LENGTH = 6;
export const MAX_OTP_LENGTH = 6;
export const MIN_PASSWORD_ERROR_MESSAGE = `Password must be a minimum of ${MIN_PASSWORD_LENGTH} characters`;
export const SESSION_EXPIRED_MESSAGE = "No active session. Kindly re-login";
export const NO_SERVER_RESPONSE_MESSAGE = "No response from Server";
export const GENERIC_ERROR_MESSAGE = "Error occurred. Please try again";
export const INVALID_EMAIL_MESSAGE = "Email is not in appropriate format";
export const INVALID_MFA_MESSAGE = "2FA code must be 6 digits";
export const PASSWORD_MATCH_MESSAGE = "Passwords must match";
export const RE_CAPTCHA_PUBLIC_KEY = "6LfL-7QqAAAAAKfTYl4gzq05pNm4pS-k6vMc6LKr";

export const MONTHS = ['January','February','March','April','May','June','July','August','September','October','November','December'];
export const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const MAX_VISIBILITY_PRIORITY = 9999999999999999999999999999999999999999999999999999999;
