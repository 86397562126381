import React, {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate } from "react-router-dom";
import { SettingsCreateValidation } from "../../../utils/validations/SettingsCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const NewSetting = () => {
      const defaultForm = {
        name: "",
        value: "",
        comment: ""
      };
      const [form, setForm] = useState(defaultForm);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {httpRequest}  = useAxios();

      useEffect(() => {
      },[]);

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      }

      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            SettingsCreateValidation.validate(form, { abortEarly: false }).then(async (values)=>{
                await httpRequest('settings/save','POST',form)
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Created successfully");
                        setTimeout(()=>{navigate(-1);},1000);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
      };
      
	
	return <AuthorizedAreas permission="CAN_ADMINISTRATION_SETTINGS_CREATE">
            <div className="card-body px-0 pt-0 pb-2">      
                    <form onSubmit={(e)=>handleSubmit(e)}>
                      <div style={{ padding: 20 }}>
                  <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-github">
                      <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                      <span className="btn-inner--text">GO BACK</span>
                    </button>
                        <div className="card mt-4" id="basic-info">
                          <div className="card-header"><h5>Settings Information</h5></div>
                          <div className="card-body pt-0">

                            <div className="row m-5">
                              <div className="col-12">
                                <div className="input-group input-group-static">
                                  <label htmlFor="name">Setting Name</label>
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="form-control"
                                    placeholder="Settings Name"
                                    value={form.name}
                                    onChange={(e)=>handleChange(e)}						
                                  />
                                </div>
                              </div>
                            </div>

                              <div className="row m-5">
                                  <div className="col-12">
                                      <div className="input-group input-group-static">
                                          <label htmlFor="value">Setting Value</label>
                                          <input
                                              autoComplete="off"
                                              type="text"
                                              name="value"
                                              id="value"
                                              className="form-control"
                                              placeholder="Settings Value"
                                              value={form.value}
                                              onChange={(e)=>handleChange(e)}
                                          />
                                      </div>
                                  </div>
                              </div>

                              <div className="row m-5">
                                  <div className="col-12">
                                    <label className="form-label" htmlFor="comment"> Settings Description</label>
                                    <textarea onChange={(e)=>handleChange(e)} className="form-control mt-3" id="comment" name="comment" value={form.comment} type="text" placeholder="Describe Settings" style={{height: "7rem"}}></textarea>
                                  </div>
                              </div>

                            <button type="submit" className="mt-5 btn btn-icon btn-github btn-lg w-100">
                                <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                                <span className="btn-inner--text">CREATE</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> 
		</AuthorizedAreas>;
}

export default NewSetting