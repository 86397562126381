import {useState, useEffect} from 'react';
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import {useLocation, useNavigate} from "react-router-dom";
import { MenuCreateValidation } from "../../../utils/validations/MenuCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const EditMenu = () => {

      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const icon = location?.state?.icon;
      const nav = location?.state?.nav;
      const permissionName = location?.state?.permissionName;
      const order = location?.state?.order;

      const defaultForm = {
          menuId: id,
          menuName: name,
          menuIcon: icon,
          menuPermissionName: permissionName,
          menuOrder: order
      };
      const [form, setForm] = useState(defaultForm);
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {httpRequest}  = useAxios();

      useEffect(() => {
      },[]);

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      }

      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            MenuCreateValidation.validate(form, { abortEarly: false }).then(async (values)=>{
                await httpRequest('menu/save/edit','PATCH',form)
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Edits Saved");
                        setTimeout(()=>{navigate(-1);},1000);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
      };
      
	
	return <AuthorizedAreas permission="CAN_ADMINISTRATION_MENU_EDIT">
            <div className="card-body px-0 pt-0 pb-2">      
                    <form onSubmit={(e)=>handleSubmit(e)}>
                      <div style={{ padding: 20 }}>
                  <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-github">
                      <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                      <span className="btn-inner--text">GO BACK</span>
                    </button>
                        <div className="card mt-4" id="basic-info">
                          <div className="card-header"><h5>Edit Menu</h5></div>
                          <div className="card-body pt-0">

                            <div className="row m-5">
                              <div className="col-12">
                                <div className="input-group input-group-static">
                                  <label htmlFor="menuName">Menu Name</label>
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="menuName"
                                    id="menuName"
                                    className="form-control"
                                    placeholder="Menu Name"
                                    value={form.menuName}
                                    onChange={(e)=>handleChange(e)}						
                                  />
                                </div>
                              </div>
                            </div>

                              <div className="row m-5">
                                  <div className="col-12">
                                      <div className="input-group input-group-static">
                                          <label htmlFor="menuIcon">Menu Icon</label>
                                          <input
                                              autoComplete="off"
                                              type="text"
                                              name="menuIcon"
                                              id="menuIcon"
                                              className="form-control"
                                              placeholder="Enter Menu Icon"
                                              value={form.menuIcon}
                                              onChange={(e)=>handleChange(e)}
                                          />
                                      </div>
                                  </div>
                              </div>

                              <div className="row m-5">
                                  <div className="col-12">
                                      <div className="input-group input-group-static">
                                          <label htmlFor="menuPermissionName">Menu Permission</label>
                                          <input
                                              autoComplete="off"
                                              type="text"
                                              name="menuPermissionName"
                                              id="menuPermissionName"
                                              className="form-control"
                                              placeholder="Enter Menu Permission"
                                              value={form.menuPermissionName}
                                              onChange={(e)=>handleChange(e)}
                                          />
                                      </div>
                                  </div>
                              </div>

                              <div className="row m-5">
                                  <div className="col-12">
                                      <div className="input-group input-group-static">
                                          <label htmlFor="menuOrder">Menu Order</label>
                                          <input
                                              autoComplete="off"
                                              type="text"
                                              name="menuOrder"
                                              id="menuOrder"
                                              className="form-control"
                                              placeholder="Enter Menu Order"
                                              value={form.menuOrder}
                                              onChange={(e)=>handleChange(e)}
                                          />
                                      </div>
                                  </div>
                              </div>



                            <button type="submit" className="mt-5 btn btn-icon btn-github btn-lg w-100">
                                <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                                <span className="btn-inner--text">SAVE EDITS</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> 
		</AuthorizedAreas>;
}

export default EditMenu