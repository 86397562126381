import React, {useEffect, useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Player } from "@lottiefiles/react-lottie-player";
import timeLottie from "../../../assets/lottie/timemanagement.json";
import passwordResetLottie from "../../../assets/lottie/passwordReset.json";
import {useNavigate, Link, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SET_ACTIVE_USER, LOGOUT} from "../../../redux/slice/authSlice";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import "../../../assets/admin/css/material-dashboard.css";
import loginStyles from "./login.module.css";
import {
	APP_NAME,
	INVALID_EMAIL_MESSAGE,
	INVALID_MFA_MESSAGE,
	MAX_LOGIN_ATTEMPT, MAX_OTP_LENGTH, MIN_PASSWORD_ERROR_MESSAGE,
	MIN_PASSWORD_LENGTH, PASSWORD_MATCH_MESSAGE, RE_CAPTCHA_PUBLIC_KEY,
	REQUEST_METHOD
} from "../../../utils/Constants";


const Login = () => {

	const [dropDownMenuShown,setDropDownMenuShown] = useState(false);
	const [accountLocked,setAccountLocked] = useState(false);
	const [authAttemptCount,setAuthAttemptCount] = useState(0);
	const [mfaSetUpRequired,setMfaSetUpRequired] = useState(false);
	const [qrcodeURI,setQrcodeURI] = useState(null);
	const [passwordAuthenticated,setPasswordAuthenticated] = useState(false);
	const [forgotPassword,setForgotPassword] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [queryParams,setQueryParams] = useSearchParams();
	const recaptchaRefLogin = useRef(null);
	const recaptchaRefForgotPassword = useRef(null);
	const loginMFARef = useRef(null);
	const {dispatchNotification} = useGlobalNotification();
	const {httpRequest}  = useAxios();
	const [loginForm, setLoginForm] = useState({
		username: "",
		password: "",
		code: "",
		captchaVal: null
	});
	const [passwordResetFormFinal, setPasswordResetFormFinal] = useState({
		username: "",
		password: "",
		passwordConfirmation: "",
		otp: "",
		passwordResetCode: null
	});

    useEffect(() => {
	if(queryParams.has("rc"))
		setPasswordResetFormFinal({ ...passwordResetFormFinal, ["passwordResetCode"] : queryParams.get("rc") });
	}, []);
   const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setLoginForm({ ...loginForm, [name]: value });
   };

	const handlePasswordResetChangeFinal = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setPasswordResetFormFinal({ ...passwordResetFormFinal, [name]: value });
	};

	const handleKeyPress = (e) =>{
	   if (e.key === "Enter")
		   handleSubmit(e);
	}
	const handleKeyPressOTP = (e) =>{
		if (e.key === "Enter")
			handleOTPSubmit(e);
	}

	const handleKeyPressFinalReset = (e) =>{
		if (e.key === "Enter")
			handleFinalPasswordReset(e);
	}

	const handleFinalPasswordReset = async (e) => {
		if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(passwordResetFormFinal.username)) {
			dispatchNotification('error', INVALID_EMAIL_MESSAGE);
			return;
		}

		if(passwordResetFormFinal.password.trim().length < MIN_PASSWORD_LENGTH || passwordResetFormFinal.passwordConfirmation.trim().length < MIN_PASSWORD_LENGTH) {
			dispatchNotification('error', MIN_PASSWORD_ERROR_MESSAGE);
			return;
		}

		if(passwordResetFormFinal.password.trim() !== passwordResetFormFinal.passwordConfirmation.trim()) {
			dispatchNotification('error', PASSWORD_MATCH_MESSAGE);
			return;
		}

		if (!/^\d{6}$/.test(passwordResetFormFinal.otp)) {
			dispatchNotification('error', INVALID_MFA_MESSAGE);
			return;
		}

		await httpRequest("forgot/password/reset",REQUEST_METHOD.PATCH,passwordResetFormFinal)
			.then((data) =>{
				if(data) {
					dispatchNotification('success', data?.message);
					queryParams.delete("rc");
					setQueryParams({});
					setPasswordResetFormFinal({
						username: "",
						password: "",
						passwordConfirmation: "",
						otp: "",
						passwordResetCode: null
					});
				}
			});
	}

	const handleSubmitPasswordReset = async (e) => {
		if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(loginForm.username)) {
			dispatchNotification('error', INVALID_EMAIL_MESSAGE);
			return;
		}

		if (recaptchaRefForgotPassword.current) {
			recaptchaRefForgotPassword.current.reset();
			setLoginForm({ ...loginForm, ["captchaVal"] : null });
		}

		await httpRequest("forgot/password/initiate",REQUEST_METHOD.POST,{"username":loginForm.username,"captchaVal":loginForm.captchaVal})
			.then((data) =>{
				if(data) {

					if (data?.data?.authAttempt) {
						dispatchNotification('error', data?.message);
						setAuthAttemptCount(parseInt(data.data.authAttempt));
						if(parseInt(data.data.authAttempt) === MAX_LOGIN_ATTEMPT)
							setAccountLocked(true);
						return;
					}
					dispatchNotification('success', data?.message);
				}

		      //setLoginForm({ ...loginForm, "username": "" });
		});
	}
	const handleOTPSubmit = async (e) =>{

		if(!/^\d{6}$/.test( loginForm.code)) {
			dispatchNotification('error', INVALID_MFA_MESSAGE);
			return;
		}

		await httpRequest("authenticate/mfa",REQUEST_METHOD.POST,loginForm)
		.then((data) =>{

			if (data?.data?.authAttempt) {
				dispatchNotification('error', data?.message);
				setAuthAttemptCount(parseInt(data.data.authAttempt));
				if(parseInt(data.data.authAttempt) === MAX_LOGIN_ATTEMPT)
					setAccountLocked(true);
				return;
			}

			if(data?.data?.auth){
				dispatch(SET_ACTIVE_USER(data.data.auth));
				navigate("/admin/dashboard");
			}

		});
	}
    const handleSubmit = async (e) => {
		if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(loginForm.username)) {
			dispatchNotification('error', INVALID_EMAIL_MESSAGE);
			return;
		}
	    if(loginForm.password.trim().length < MIN_PASSWORD_LENGTH || loginForm.password.trim() === "") {
			dispatchNotification('error', MIN_PASSWORD_ERROR_MESSAGE);
			return;
		}

		//reset after every submission
		if (recaptchaRefLogin.current) {
			recaptchaRefLogin.current.reset();
			setLoginForm({ ...loginForm, ["captchaVal"] : null });
		}

		await httpRequest("authenticate",REQUEST_METHOD.POST,loginForm)
			.then((data) =>{
				if(data) {

					if (data?.data?.qrcodeURI) {
						setMfaSetUpRequired(true);
						setQrcodeURI(data?.data?.qrcodeURI);
						return;
					}

					if (data?.data?.authAttempt) {
						dispatchNotification('error', data?.message);
						 setAuthAttemptCount(parseInt(data.data.authAttempt));
						 if(parseInt(data.data.authAttempt) === MAX_LOGIN_ATTEMPT)
						  setAccountLocked(true);
						return;
					}
					setAuthAttemptCount(0);
					setPasswordAuthenticated(true);
					if(loginMFARef?.current)
					loginMFARef.current.focus();
				}
			});

	};

	return (
		<main className={`main-content  mt-0 ${loginStyles["dms-login-page-overflow-hidden"]}`}>
			<div className={loginStyles["dms-navbar"]}>
				<div className={loginStyles["dms-logo"]}>
					<img src={require("../../../uonsu_logo.png")} className="img-fluid animate__animated animate__pulse" alt="UoNSU Logo"/>{APP_NAME}
				</div>
				{/*<ul className={`dms-menu ${dropDownMenuShown ? "active" : ""}`}>*/}
				<ul className={`${loginStyles["dms-menu"]} ${dropDownMenuShown ? "active" : ""}`}>
					<li><Link to="/">Home</Link></li>
					<li><Link to="/accident-report">Accident Reports</Link></li>
				</ul>
				<div onClick={()=>setDropDownMenuShown(!dropDownMenuShown)} className={loginStyles["dms-hamburger"]}>&#9776;</div>
			</div>

			<section>
				<div className="page-header min-vh-100 mt-3">
					<div className="container">
						<div className="row">
							<div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
								<Player
									autoplay
									loop
									style={{ height: "100vh", width: "100%" }}
									src={timeLottie}
								/>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
								<div className="card card-plain">
									<div className="card-header text-center">
										<img style={{ borderRadius : "15px"}} src={require("../../../uonsu_logo.png")} className="img-fluid animate__animated animate__pulse" alt="UoNSU Logo"/>
										<h4 className="font-weight-bolder">
											{APP_NAME}
										</h4>
										{
											    passwordResetFormFinal?.passwordResetCode ?
												<p className="mb-0">Password Reset</p>
												:
												mfaSetUpRequired === false && passwordAuthenticated===false ?
												  <p className="mb-0">{ forgotPassword ? "Enter Email to reset password"  :"Enter your email and password to sign in"}</p>
												:
												<p className="mb-0">MFA Authentication</p>
										}
									</div>

										<div className="card-body mt-2">

											{
												passwordResetFormFinal?.passwordResetCode ?
													<div className={"pb-2"}>
														<Player
															autoplay
															style={{ height: "200px", width: "100%" }}
															src={passwordResetLottie}
														/>
														<small style={{textAlign: "justify", display: "inline-block"}}>You have requested a password reset. Kindly input the fields below to continue.</small>
														<div className="form-floating mb-3 mt-3">
															<input name="username" type="email" id="username"
																   className={`form-control  ${ !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(passwordResetFormFinal.username) ? "is-invalid" : "is-valid"}`}
																   value={passwordResetFormFinal.username}
																   autoComplete={"on"}
																   onChange={(e)=>handlePasswordResetChangeFinal(e)}
																   placeholder="Enter email address"/>
															<label htmlFor="username">Email address</label>
														</div>
														<div className="form-floating mb-3">
															<input name="password" type="password" className={`form-control ${passwordResetFormFinal.password.trim() === "" || passwordResetFormFinal.password.trim().length < MIN_PASSWORD_LENGTH ? "is-invalid" : "is-valid"} `} id="password"
																   value={passwordResetFormFinal.password}
																   onChange={(e)=>handlePasswordResetChangeFinal(e)}
																   placeholder="Password"/>
															<label htmlFor="password">New Password</label>
														</div>
														<div className="form-floating mb-3">

															<input name="passwordConfirmation" type="password" className={`form-control ${passwordResetFormFinal.passwordConfirmation.trim() === "" || passwordResetFormFinal.passwordConfirmation.trim().length < MIN_PASSWORD_LENGTH || passwordResetFormFinal.passwordConfirmation !== passwordResetFormFinal.password? "is-invalid" : "is-valid"} `} id="passwordConfirmation"
																   value={passwordResetFormFinal.passwordConfirmation}
																   onChange={(e)=>handlePasswordResetChangeFinal(e)}
																   placeholder="Password Confirmation"/>
															<label htmlFor="passwordConfirmation">Password Confirmation</label>
														</div>

														<div className="form-floating mb-3">
															<input name="otp" type="text" id="otp" maxLength={MAX_OTP_LENGTH}
																   className={`form-control  ${ !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(passwordResetFormFinal.username) ? "is-invalid" : "is-valid"}`}
																   value={passwordResetFormFinal.otp}
																   autoComplete={"off"}
																   onChange={(e)=>handlePasswordResetChangeFinal(e)}
																   onKeyDown={(e)=>handleKeyPressFinalReset(e)}
																   placeholder="Enter OTP Code"/>
															<label htmlFor="otp">OTP code</label>
														</div>


														<div className="text-center">
															<button
																style={{backgroundColor: "#000", color: "#FFF"}}
																onClick={()=>handleFinalPasswordReset()}
																disabled={ !( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(passwordResetFormFinal.username) ||  passwordResetFormFinal.username.trim() === "" || passwordResetFormFinal.passwordConfirmation.trim() === "" || passwordResetFormFinal.otp.trim() === ""}
																type="button"
																className="btn btn-lg btn-lg w-100 mt-4 mb-0"
															>
																RESET PASSWORD
															</button>
														</div>
													</div>
													:
												    mfaSetUpRequired === false && passwordAuthenticated===false ?
													<>
														{!forgotPassword && authAttemptCount && authAttemptCount > 0?
															<div className={"pb-2"}>
																{accountLocked ?
																	<div className={"text-center"}>
																		<img src={require("./../../../assets/banned.png")} className="img-fluid animate__animated animate__pulse" alt="Banned"/>
																		<small style={{textAlign: "center", display: "inline-block"}}>Your account has been temporarily suspended. Please contact admin</small>
																	</div>:
																	<>
																		<p className={"text-center"}>Attempt <span>{authAttemptCount}</span> of <span className="badge badge-success" style={{fontWeight:700}}>{MAX_LOGIN_ATTEMPT}</span></p>
																		<small style={{textAlign: "justify", display: "inline-block"}}>For your security, your account will be temporarily locked after the maximum number of failed login attempts.</small>
																	</>
																  }

															</div>
															:
															null}
														<div className="form-floating mb-3">
															<input name="username" type="email" id="username"
																   className={`form-control  ${ !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(loginForm.username) ? "is-invalid" : "is-valid"}`}
																   value={loginForm.username}
																   autoComplete={"on"}
																   onChange={(e)=>handleChange(e)}
																   placeholder="Enter email address"/>
															<label htmlFor="username">Email address</label>
														</div>
														{forgotPassword ?
															<>
																<div style={{display : "flex", flexDirection : "column" , justifyContent : "center" , alignItems : "center" , paddingBlock : "20px"}}>
																	<ReCAPTCHA ref={recaptchaRefForgotPassword} sitekey={RE_CAPTCHA_PUBLIC_KEY} onChange={(val)=> setLoginForm({ ...loginForm, ["captchaVal"] : val }) }/>
																</div>
																<div className="text-center">
																	<button
																		style={{backgroundColor: "#000", color: "#FFF"}}
																		onClick={()=>handleSubmitPasswordReset()}
																		disabled={ !( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(loginForm.username) ||  loginForm.username.trim() === "" || !loginForm?.captchaVal}
																		type="button"
																		className="btn btn-lg btn-lg w-100 mt-4 mb-0"
																	>
																		RESET PASSWORD RESET
																	</button>
																</div>
															</> :
															<>

																<div className="form-floating">
																	<input name="password" type="password" className={`form-control ${loginForm.password.trim() === "" || loginForm.password.trim().length < MIN_PASSWORD_LENGTH ? "is-invalid" : "is-valid"} `} id="password"
																		   value={loginForm.password}
																		   onChange={(e)=>handleChange(e)}
																		   onKeyDown={(e)=>handleKeyPress(e)}
																		   placeholder="Password"/>
																		<label htmlFor="password">Password</label>
																</div>

																<div style={{display : "flex", flexDirection : "column" , justifyContent : "center" , alignItems : "center" , paddingBlock : "20px"}}>
																	<ReCAPTCHA ref={recaptchaRefLogin} sitekey={RE_CAPTCHA_PUBLIC_KEY} onChange={(val)=> setLoginForm({ ...loginForm, ["captchaVal"] : val }) } />
																</div>

																<div className="text-center">
																	<button
																		style={{backgroundColor: "#000", color: "#FFF"}}
																		onClick={()=>handleSubmit()}
																		disabled={ !( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(loginForm.username) ||  loginForm.username.trim() === "" || loginForm.password.trim().length < MIN_PASSWORD_LENGTH || loginForm.password.trim() === "" || !loginForm?.captchaVal || accountLocked}
																		type="button"
																		className="btn btn-lg btn-lg w-100 mt-4 mb-0"
																	>
																		LOGIN
																	</button>
																</div>
														  </>
														}

														<div className="text-center pt-3">
														<Link to="#" onClick={()=>setForgotPassword(!forgotPassword)}>

															<span>
																{
																	forgotPassword ?
																	<i className="material-icons-round" style={{verticalAlign: "middle"}}>keyboard_backspace</i>:
																	<i className="material-icons-round" style={{verticalAlign: "middle"}}>lock_reset</i>
																}

																{ forgotPassword ? "Back to Login" : "Forgot Password" }

															</span>
														</Link>
														</div>
													</>
													 :
													<>
													{
														mfaSetUpRequired ?
														<div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
															<img style={{width: "200px", height: "200px"}} src={qrcodeURI}  alt={"QRCode"}/>
															<h5 style={{marginTop: "15px"}}>MFA Configuration</h5>
															<p style={{textAlign : "justify"}}>Kindly set up your Two Factor Authentication (2FA) for your account. You can use an Authenticator like Google Authenticator or Microsoft Authenticator to scan the OR Code. Enter the generated code when done</p>
														</div>
														:
														null
													}

														{authAttemptCount && authAttemptCount > 0?
															accountLocked ?
																	<div className={"text-center"}>
																		<img src={require("./../../../assets/banned.png")} className="img-fluid animate__animated animate__pulse" alt="Banned"/>
																		<small style={{textAlign: "center", display: "inline-block"}}>Your account has been temporarily suspended. Please contact admin.</small>
																	</div>:
																	<>
																		<p className={"text-center"}>Attempt <span>{authAttemptCount}</span> of <span className="badge badge-success" style={{fontWeight:700}}>{MAX_LOGIN_ATTEMPT}</span></p>
																		<small style={{textAlign: "justify", display: "inline-block"}}>For your security, your account will be temporarily locked after the maximum number of failed login attempts.</small>
																	</>
															:
															null}
														<div className="form-floating">
															<input maxLength={MAX_OTP_LENGTH} name="code" type="text" className={`form-control ${loginForm.code.trim() === "" || loginForm.code.trim().length < MIN_PASSWORD_LENGTH ? "is-invalid" : "is-valid"} `} id="code"
																   value={loginForm.code}
																   ref={loginMFARef}
																   onChange={(e)=>handleChange(e)}
																   onKeyDown={(e)=>handleKeyPressOTP(e)}
																   placeholder="Enter code from Authenticator"/>
															<label htmlFor="code">Please enter OTP Code</label>
														</div>

														<div className="text-center">
															<button
																style={{backgroundColor: "#000", color: "#FFF"}}
																onClick={()=>handleOTPSubmit()}
																disabled={ loginForm.code.trim() === "" || loginForm.code.trim().length < MIN_PASSWORD_LENGTH || loginForm.code.trim() === "" || accountLocked}
																type="button"
																className="btn btn-lg btn-lg w-100 mt-4 mb-0"
															>
																SUBMIT OTP
															</button>
														</div>
													</>
											}
										</div>


									<div className="card-footer text-center pt-0 px-lg-2 px-1">
										<p className="mb-4 text-sm mx-auto">
											© 2022 , University of Nottingham Student Union Data Management System
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default Login;
