import { NavLink, Link } from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import { LOGOUT } from "../../redux/slice/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import useAxios from "../../utils/hooks/useAxios";
import {APP_NAME, REQUEST_METHOD} from "../../utils/Constants";
import {useEffect, useState} from "react";

const SideNav = () => {
    const {name,menu,subMenu} = useSelector((state)=>state.auth);
	const [activeMenu,setActiveMenu] = useState(null);
	const [currentPath, setCurrentPath] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const {httpRequest}  = useAxios();

	const logOut = async () =>{
		await httpRequest('logout',REQUEST_METHOD.PATCH)
		.then((data) =>{
			dispatch(LOGOUT());
			//navigate('/',{ state: { status: 'loggedOut'} });
		});
	}

	const defaultCollapseState = () =>{
		const curPath = location.pathname;
		if(curPath && subMenu) {
			const result = subMenu.find(subMenu => subMenu?.subMenuNav === curPath);
			if(result?.subMenuMenuId)
				setActiveMenu(result?.subMenuMenuId)
		}
	}

	const activeSubMenuState = () =>{
		setCurrentPath(location.pathname);
	}

	useEffect(()=>{
		defaultCollapseState();
	},[])

	useEffect(()=>{activeSubMenuState()},[location])

  return (
		<aside
		onClick={()=>window.toggleSidenav()}
			className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
			id="sidenav-main"
		>
			<div className="sidenav-header">
				<i
					className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
					aria-hidden="true"
					id="iconSidenav"
				/>
				<NavLink to="/admin/dashboard" className="navbar-brand m-0">
					<img
						src={require("../../uonsu_logo.png")}
						className="navbar-brand-img h-100"
						alt="main logo"
					/>
					<span className="ms-1 font-weight-bold text-black">
						{APP_NAME}
					</span>
				</NavLink>
			</div>
			<hr className="horizontal black mt-0 mb-2" />
			<div className="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
				<ul className="navbar-nav">
					<>
						<li className="nav-item mb-2 mt-0">
							<a
								data-bs-toggle="collapse"
								href="#ProfileNav"
								className="nav-link text-black"
								aria-controls="ProfileNav"
								role="button"
								aria-expanded="false"
							>
								{/* <img src={require("../../assets/admin/img/team-3.jpg")} className="avatar" alt="" /> */}
								<i className='material-icons-round'>account_circle</i>
								<span className="nav-link-text ms-2 ps-1 text-bold">Welcome {name}</span>
							</a>
							<div className="collapse" id="ProfileNav">
								<ul className="nav ">
									{/*<li className="nav-item">
										<a
											className="nav-link text-white"
											href="#j"
										>
											<span className="sidenav-mini-icon"> MP </span>
											<span className="sidenav-normal  ms-3  ps-1">
												{" "}
												My Profile{" "}
											</span>
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link text-white "
											href="#h"
										>
											<span className="sidenav-mini-icon"> S </span>
											<span className="sidenav-normal  ms-3  ps-1">
												{" "}
												Settings{" "}
											</span>
										</a>
									</li>*/}
									<li className="nav-item">
										<Link
											to="#logOut"
											onClick={()=>logOut()}
											className='nav-link'
										>
											{/*<i className='material-icons-round'>logout</i>*/}
											<span className="sidenav-mini-icon"> L </span>
											<span className="sidenav-normal  ms-3  ps-1">Log Out</span>
										</Link>
									</li>
								</ul>
							</div>
						</li>
						<hr className="horizontal black mt-0"/>
					</>

					<li className="nav-item">
						<h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder text-black">
							MODULES
						</h6>
					</li>
					<li className={`nav-item`}>
						<NavLink
							end
							to="/admin/dashboard"
							className={`nav-link ${currentPath==="/admin/dashboard"? "inf-active-dashboard":""}`}
						>
							<i className='material-icons-round'>dashboard</i>
							<span className="nav-link-text ms-2 ps-1">Dashboard</span>
						</NavLink>
					</li>

					{menu.map((m) => {
						return (
							<li key={m.menuId} className="nav-item nav-inf-parent">
								<a href={`#${m.menuName.replaceAll(" ","")}`} className="nav-link text-dark" data-bs-toggle="collapse" aria-expanded="false">
									<i className='material-icons-round'>{m.menuIcon}</i>
									<span className="sidenav-normal ms-1 ps-1">{m.menuName}<b className="caret"></b></span>
								</a>
								{subMenu.length?
								<div className={`collapse ${activeMenu === m.menuId ? "show" : ""} `} id={m.menuName.replaceAll(" ","")}>
									<ul className="nav nav-sm flex-column">
										{subMenu.map((s)=> {
											return (
												s.subMenuMenuId === m.menuId ?
												<li className="nav-item">
													<NavLink to={s.subMenuNav} className={`nav-link inf-nav-link text-dark ${currentPath===s.subMenuNav? "inf-active-submenu":""}`} >
														<span className="sidenav-mini-icon"> {s.subMenuName[0]} </span>
														<span className="sidenav-normal ms-1 ps-1"> {s.subMenuName}</span>
													</NavLink>
												</li>
													:
													null
										);
										})}
									</ul>
								</div>:null}
							</li>
						);
					})}

					<li className='nav-item'>
						<Link 
						to="#logOut" 
						onClick={()=>logOut()}
						className='nav-link'
						>
							<i className='material-icons-round'>logout</i>
							<span className='nav-link-text ms-2 ps-1'>Log Out</span>
						</Link>
					</li>
				</ul>
			</div>
		</aside>
	);
}

export default SideNav