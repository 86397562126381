import "./AccidentReport.css";
import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import submittedLottie from "../../../assets/lottie/submitted.json";
import {Player} from "@lottiefiles/react-lottie-player";
import ReCAPTCHA from "react-google-recaptcha";
import "react-datepicker/dist/react-datepicker.css";
import "./AccidentReport.css";
import {INVALID_EMAIL_MESSAGE, RE_CAPTCHA_PUBLIC_KEY, REQUEST_METHOD} from "../../../utils/Constants";

function AccidentReport() {
    const {httpRequest}  = useAxios();
    const {dispatchNotification} = useGlobalNotification();
    const [isNextClicked, setIsNextClicked] = useState(false);
    const [page,setPage] = useState(1);
    const [saved, setSaved] = useState(false);
    const [suDepartment, setSuDepartment] = useState([]);

    const defaultForm = {

        //data check
        doneFormattingForServer: false,

        //section 1
        mostAccurateReflectsAccidentOrIncident: "", //Staff - Student - All - None
        whatTypeOfStudentGroupAreYouReportingFor:"",
        whatIsTheNameofYourSocietyCluborGroup:"",
        whatIsYourSUDepartment:"",
        whatAreYouReportingFor: "", //accident damage theft near_miss

        //section 2
        injuredPersonsFullName:"",
        injuredPersonsAddress:"",
        injuredPersonsPostcode:"",
        injuredPersonsAge:"",
        injuredPersonsInjuryType:"",
        injuredPersonsInjuryLocation:"",
        injuredPersonsFirstAid:"",
        injuredPersonsPermission:"",

        //section 3
        didTheEventHappenOnAUniversityCampus:"",
        whereDidTheEventHappen:"",
        whenDidTheAccidentHappen:"",
        pleaseDescribeHowTheEventHappened:"",
        //--Accident Injury Only
        afterTheInjuryDidAnyOfTheFollowingINJURY:[],
        whatCausedTheAccident:"",
        whatCausedTheAccidentOtherReason:"", //if above is Other
        doAnyFirstAidKitsNeedToBeRefilled:"",
        //--Accident Damage Only
        whatHasBeenDamagedOrBroken:[],
        whatHasBeenDamagedOrBrokenDescription: "",//compulsory if the above is filled
        //Accident Damage & Incident Only
        afterTheEventDidAnyOfTheFollowingOthers:[],

        //section 4
        didAnybodyElseWitnessTheEvent:"",
        witness1Name:"",
        witness1Contact:"",
        witness2Name:"",
        witness2Contact:"",
        additionalWitnesses:"",

        //section 5
        fillerFullName:"",
        fillerEmailAddress:"",
        fillerAdditionalContact:"",

        captchaVal: null

    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData(prev=>({ ...prev, [name]: value }));
    };

    const [data,setData] = useState(defaultForm);

    const handleBack = () =>{
        if(page===3 && data.whatAreYouReportingFor !== "ACCIDENT - Injury")
            setPage(1);
        else
            setPage(page-1 || 1);

        window.scrollTo({ top: 0, left: 0});
    }

    const alertUserError = () => {
        if(document.querySelector("div.error_invalid"))
            document.querySelector("div.error_invalid").scrollIntoView({ behavior: "smooth"});

        dispatchNotification('error','Kindly fill out all required fields');
    }

    const handleErrorAndSetNextPage = () =>{
        let contains_error = false;
        setIsNextClicked(true);

        switch(page){
            case 1:
                    if(data.mostAccurateReflectsAccidentOrIncident === "" || data.whatAreYouReportingFor === "")
                        contains_error=true;
                    else if(data.mostAccurateReflectsAccidentOrIncident === "users" && data.whatIsYourSUDepartment===""){
                        contains_error=true;
                    }else if(data.whatTypeOfStudentGroupAreYouReportingFor === "student" && (data.whatTypeOfStudentGroupAreYouReportingFor === "" || data.whatIsTheNameofYourSocietyCluborGroup === "") ){
                        contains_error=true;
                    }

                    if(contains_error){
                        alertUserError();
                        return;
                    }


                    if(data.whatAreYouReportingFor === "ACCIDENT - Injury")
                        setPage(2);
                    else
                        setPage(3);

                    setIsNextClicked(false);
                    window.scrollTo({ top: 0, left: 0});

                break;
            case 2:

                if(
                    data.injuredPersonsFullName === "" || data.injuredPersonsAddress === ""  || data.injuredPersonsAge === ""
                 || data.injuredPersonsInjuryType === ""  || data.injuredPersonsInjuryLocation === "" || data.injuredPersonsFirstAid === "" || data.injuredPersonsPermission === ""
                )
                    contains_error=true;

                if(contains_error){
                    alertUserError();
                    return;
                }

                setPage(3);
                setIsNextClicked(false);
                window.scrollTo({ top: 0, left: 0});

                break;
            case 3:
                if(data.didTheEventHappenOnAUniversityCampus === "" || data.whereDidTheEventHappen === "" || data.whenDidTheAccidentHappen === "" || data.pleaseDescribeHowTheEventHappened === "")
                    contains_error = true;

                if(data.whatAreYouReportingFor === "ACCIDENT - Injury" && (data.afterTheInjuryDidAnyOfTheFollowingINJURY.length === 0 || data.whatCausedTheAccident === "" | data.doAnyFirstAidKitsNeedToBeRefilled === ""))
                    contains_error = true;

                if(data.whatAreYouReportingFor === "ACCIDENT - Injury" && data.whatCausedTheAccident === "Other" && data.whatCausedTheAccidentOtherReason === "")
                    contains_error = true;

                if(data.whatAreYouReportingFor === "ACCIDENT - Damage" && (data.whatHasBeenDamagedOrBroken.length === 0 || data.whatHasBeenDamagedOrBrokenDescription.trim() === "" ) )
                    contains_error = true;

                if(data.afterTheEventDidAnyOfTheFollowingOthers.length === 0 && (data.whatAreYouReportingFor === "ACCIDENT - Damage" || data.whatAreYouReportingFor === "ACCIDENT - Injury")) {
                    contains_error = true;
                }

                if(contains_error){
                    alertUserError();
                    return;
                }

                setPage(4);
                setIsNextClicked(false);
                window.scrollTo({ top: 0, left: 0});

                break;
            case 4:
                if(data.didAnybodyElseWitnessTheEvent === "")
                    contains_error = true;
                if(data.didAnybodyElseWitnessTheEvent === "YES" && (data.witness1Name==="" || data.witness1Contact===""))
                    contains_error = true;

                if(contains_error){
                    alertUserError();
                    return;
                }

                setPage(5);
                setIsNextClicked(false);
                window.scrollTo({ top: 0, left: 0});

                break;
            default:
                break;
        }
    }

    const handleSubmit = async () =>{
        let contains_error = false;
        setIsNextClicked(true);

        if(data.fillerFullName === "" || data.fillerEmailAddress === "" ) {
            contains_error = true;
            alertUserError();
        }
        else if(!( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(data.fillerEmailAddress)){
            contains_error = true;
            dispatchNotification('error',INVALID_EMAIL_MESSAGE);
        }

        if(!contains_error){
            await httpRequest("accident_reports/save",REQUEST_METHOD.POST,data)
                .then((data) =>{
                    if(data){
                        setSaved(true);
                    }
                });
        }
    }

    useEffect( ()=>{
         httpRequest("/accident_reports/departments",REQUEST_METHOD.GET)
            .then((data) =>{
                if(data?.data?.departments){
                    setSuDepartment(data?.data?.departments);
                }
            });
    },[]);


    return (
    <div className="container main card" style={{width:"60%", marginTop: "40px", borderRadius: "15px", padding: "0", border: "0"}}>
        <div className="topFormSection" style={{backgroundColor:"#ede9dd"}}>
            {page!==1 || saved === true ?
            <div className="animate__animated animate__pulse">
                <img style={{ borderRadius : "15px", float: "left", marginRight:"10px"}} src={require("../../../uonsu_logo.png")} className="img-fluid" alt="UoNSU Logo"/>
                <h3 style={{paddingTop: "35px", paddingLeft: "15px", textAlign: "left", color:"black", fontWeight: "300" }}>UoN Students' Union Accident Report Form</h3>
            </div>:
              <>
                <div className="text-center">
                <img style={{width:"90px", height: "100px" , borderRadius : "15px"}} src={require("../../../uonsu_logo.png")} className="img-fluid animate__animated animate__pulse" alt="UoNSU Logo"/>
                    <h3 style={{paddingTop: "15px", paddingLeft: "15px", color:"#252422"}}><strong>UoN Students' Union Accident Report Form</strong></h3>
                </div>
                <p style={{clear: "both", textAlign:"justify", paddingTop : "5px", color:"#252422"}}>This Form is to report any Accident, Near-miss or Incident that occurs as part of Students' Union activity.
                    All information collected will be stored securely and confidentially, in compliance with all data privacy legislation for a period of not less than 3 years.
                </p>
                <p style={{color:"#000", fontWeight: 800, textAlign: "center"}}>PLEASE READ EACH QUESTION THOROUGHLY AND ANSWER AS ACCURATELY AS POSSIBLE.</p>
            </>
            }
        </div>

        <div className="bottomFormSection">

            {saved === false && page === 1  ?
            <div className="alert alert-light" role="alert">
                <h5 className="alert-heading"> <i className="bi-bookmark-heart-fill" style={{color:"#171635"}}></i> Some definitions:</h5>
                <ul className="accidentReport">
                    <li className="justifyText">An <strong>ACCIDENT - Injury</strong> is an unplanned event that causes injury, pain or other bodily harm to a person/people</li>
                    <li className="justifyText">An <strong>ACCIDENT - Damage</strong> is an unplanned event that has caused equipment, property or vehicles to become damaged</li>
                    <li className="justifyText">An <strong>INCIDENT</strong> is any other undesired or dangerous occurrence that does not fall into either of the categories above - e.g. Theft, Verbal Abuse, Hate-Crimes etc.</li>
                    <li className="justifyText">A <strong>NEAR-MISS</strong> is an occurrence that did not cause injury harm or damage but would have done under different circumstances</li>
                </ul>
                <hr/>
                    <p className="mb-0"><strong className="text-danger">*</strong> Required fields</p>
            </div>:null}

            {saved === true ?
                <div className="row">
                    <Player
                        autoplay
                        loop
                        style={{  width: "250px", position: "relative" }}
                        src={submittedLottie}
                    />
                    <p className="pt-3">Thank you for submitting this Accident/Incident Report Form. </p>
                    <p>Your submission will now be sent to the Union's H&S Team, who may be in contact with you if more information is needed.</p>
                    <p>By reporting this Accident/Incident you will be helping to ensure that the Students' Union is a safer place to be.</p>
                    <p>Thank You - You can now close this window.</p>
                    <button className="mt-3 btn btn-github" onClick={()=>{
                        setSaved(false);
                        setPage(1);
                        setIsNextClicked(false);
                        setData(defaultForm);
                    }} type="button">Submit Another Form</button>
                </div>
                :
             <div className="row pt-4">

              {/*Introduction*/}

              {page === 1  ?
                  <div>

                      <div
                          className={`
                          mb-5 
                          ${ isNextClicked === true && data.mostAccurateReflectsAccidentOrIncident !=="" && "success_valid" }
                          ${ isNextClicked === true && data.mostAccurateReflectsAccidentOrIncident ==="" && "error_invalid" }
                          `}
                          onChange={(e)=>handleChange(e)}
                      >
                          <label className="form-label d-block">
                              <strong className="text-danger">*</strong>  Which of the following statements <strong>MOST ACCURATELY</strong> reflects the accident/incident?
                          </label>
                          <div className="form-check">
                              <input className="form-check-input"
                                     type="radio"
                                     name="mostAccurateReflectsAccidentOrIncident"
                                     id="mostAccurateReflectsAccidentOrIncidentStudent"
                                     value="STUDENT - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)"
                                     checked={data.mostAccurateReflectsAccidentOrIncident === "STUDENT - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)"}
                                     autoComplete="off"
                                     />
                              <label className="form-check-label" htmlFor="mostAccurateReflectsAccidentOrIncidentStudent">
                                  <strong>STUDENT</strong> - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)
                              </label>
                          </div>
                          <div className="form-check">
                              <input className="form-check-input"
                                     type="radio"
                                     name="mostAccurateReflectsAccidentOrIncident"
                                     id="mostAccurateReflectsAccidentOrIncidentStaff"
                                     value="STAFF - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work"
                                     checked={data.mostAccurateReflectsAccidentOrIncident === "STAFF - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work"}
                                     autoComplete="off"
                                     />
                              <label className="form-check-label" htmlFor="mostAccurateReflectsAccidentOrIncidentStaff">
                                  <strong>STAFF</strong> - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work
                              </label>
                          </div>
                          {/*<div className="form-check">
                              <input className="form-check-input"
                                     type="radio"
                                     name="mostAccurateReflectsAccidentOrIncident"
                                     checked={data.mostAccurateReflectsAccidentOrIncident === "All of the above"}
                                     id="mostAccurateReflectsAccidentOrIncidentAll"
                                     value="All of the above"
                                     autoComplete="off"
                                     />
                              <label className="form-check-label" htmlFor="mostAccurateReflectsAccidentOrIncidentAll">All of the above</label>
                          </div>*/}
                          <div className="form-check">
                              <input className="form-check-input"
                                     type="radio"
                                     name="mostAccurateReflectsAccidentOrIncident"
                                     value="None of the above"
                                     id="mostAccurateReflectsAccidentOrIncidentNone"
                                     checked={data.mostAccurateReflectsAccidentOrIncident === "None of the above"}
                                     autoComplete="off"
                                     />
                              <label className="form-check-label" htmlFor="mostAccurateReflectsAccidentOrIncidentNone">None of the above</label>
                          </div>
                      </div>

                      {
                          data.mostAccurateReflectsAccidentOrIncident === "STUDENT - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)"?
                         <>
                             <div
                                 className={`
                                  mb-5 
                                  ${ isNextClicked === true && data.whatTypeOfStudentGroupAreYouReportingFor !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.whatTypeOfStudentGroupAreYouReportingFor ==="" && "error_invalid" }
                                  `}
                                 onChange={(e)=>handleChange(e)}
                             >
                                  <label className="form-label d-block" htmlFor="whatTypeOfStudentGroupAreYouReportingFor">
                                      <strong className="text-danger">*</strong> What Type of Student Group are you reporting for?
                                  </label>
                                  <select
                                      defaultValue=""
                                      name="whatTypeOfStudentGroupAreYouReportingFor"
                                      className="form-select"
                                      id="whatTypeOfStudentGroupAreYouReportingFor"
                                      aria-label="What Type of Student Group are you reporting for?"
                                      value={data.whatTypeOfStudentGroupAreYouReportingFor}
                                  >
                                      <option value="">--Please select an option--</option>
                                      <option value="UP Based Society">UP Based Society</option>
                                      <option value="Sports Club (UonSport Affiliated)">Sports Club (UonSport Affiliated)</option>
                                      <option value="SB Based Society / Club">SB Based Society / Club</option>
                                      <option value="MedSoc Club / Society">MedSoc Club / Society</option>
                                      <option value="UNAD Club / Society">UNAD Club / Society</option>
                                      <option value="NMA /SUPRA">NMA /SUPRA</option>
                                      <option value="A Network">A Network</option>
                                      <option value="TEC PA & Lighting">TEC PA & Lighting</option>
                                      <option value="Student Run Services e.g (URN,NNT)">Student Run Services e.g (URN,NNT)</option>
                                      <option value="Associated Body e.g (SBGuild, Medsoc, UNAD e.t.c)">Associated Body e.g (SBGuild, Medsoc, UNAD e.t.c)</option>
                                      <option value="Other">Other</option>
                                  </select>
                              </div>


                             <div
                                 className={`
                                  mb-5 
                                  ${ isNextClicked === true && data.whatIsTheNameofYourSocietyCluborGroup !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.whatIsTheNameofYourSocietyCluborGroup ==="" && "error_invalid" }
                                  `}
                                 onChange={(e)=>handleChange(e)}
                             >
                                  <label className="form-label" htmlFor="whatIsTheNameofYourSocietyCluborGroup"> <strong className="text-danger">*</strong> What is the Name of your Society, Club or Group?</label>
                                  <small>e.g AdminSoc</small>
                                  <input className="form-control" id="whatIsTheNameofYourSocietyCluborGroup" name="whatIsTheNameofYourSocietyCluborGroup" value={data.whatIsTheNameofYourSocietyCluborGroup} type="text" placeholder="Enter your answer" />
                                  <div className="invalid-feedback" data-sb-feedback="newField4:required">Field is required.</div>
                              </div>
                         </>

                          :null
                      }

                      {
                          data.mostAccurateReflectsAccidentOrIncident === "None of the above" || data.mostAccurateReflectsAccidentOrIncident === "STAFF - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work"?
                              <div
                                  className={`
                                  mb-5 
                                  ${ isNextClicked === true && data.whatIsYourSUDepartment !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.whatIsYourSUDepartment ==="" && "error_invalid" }
                                  `}
                                  onChange={(e)=>handleChange(e)}
                              >
                          <label htmlFor="whatIsYourSUDepartment" className="form-label">
                              <strong className="text-danger">*</strong> What is your SU Department?
                          </label>
                          <select
                              defaultValue=""
                              className="form-select"
                              id="whatIsYourSUDepartment"
                              name="whatIsYourSUDepartment"
                              aria-label="What is your SU Department?"
                              value={data.whatIsYourSUDepartment}
                          >
                              <option value="">--Please select an option--</option>
                              {
                                  suDepartment.length?
                                  suDepartment.map(
                                      (d)=>(<option key={d.departmentId} value={d.departmentName}>{d.departmentName}</option>)
                                  )
                                  :
                                  null
                              }
                          </select>
                      </div>:
                              null
                      }

                      <div
                          className={`
                          mb-5 
                          ${ isNextClicked === true && data.whatAreYouReportingFor !=="" && "success_valid" }
                          ${ isNextClicked === true && data.whatAreYouReportingFor ==="" && "error_invalid" }
                          `}
                          onChange={(e)=>handleChange(e)}
                      >
                          <label htmlFor="whatAreYouReporting" className="form-label d-block">
                              <strong className="text-danger">*</strong>  What are you Reporting?

                              <small>Please refer to definitions previously</small>
                          </label>
                          <div className="form-check">
                              <input className="form-check-input" type="radio"
                                     name="whatAreYouReportingFor"
                                     id="whatAreYouReportingForAccidentInjury"
                                     value="ACCIDENT - Injury"
                                     checked={data.whatAreYouReportingFor === "ACCIDENT - Injury"}
                                     />
                              <label className="form-check-label" htmlFor="whatAreYouReportingForAccidentInjury"><strong>ACCIDENT</strong> - Injury</label>
                          </div>
                          <div className="form-check">
                              <input className="form-check-input"  type="radio"
                                     name="whatAreYouReportingFor"
                                     id="whatAreYouReportingForAccidentDamage"
                                     value="ACCIDENT - Damage"
                                     checked={data.whatAreYouReportingFor === "ACCIDENT - Damage"}
                                     />
                              <label className="form-check-label" htmlFor="whatAreYouReportingForAccidentDamage"><strong>ACCIDENT</strong> - Damage</label>
                          </div>
                          <div className="form-check">
                              <input className="form-check-input" type="radio"
                                     name="whatAreYouReportingFor"
                                     id="whatAreYouReportingForIncident"
                                     value="INCIDENT"
                                     checked={data.whatAreYouReportingFor === "INCIDENT"}
                                     />
                              <label className="form-check-label" htmlFor="whatAreYouReportingForIncident"><strong>INCIDENT</strong> - e.g Theft, Abuse etc. </label>
                          </div>
                          <div className="form-check">
                              <input className="form-check-input"  type="radio"
                                     name="whatAreYouReportingFor"
                                     id="whatAreYouReportingForNearMiss"
                                     value="NEAR-MISS"
                                     checked={data.whatAreYouReportingFor === "NEAR-MISS"}
                                     />
                              <label className="form-check-label" htmlFor="whatAreYouReportingForNearMiss"><strong>NEAR-MISS</strong></label>
                          </div>
                      </div>

                  </div>
                  : null
              }

              {/* Injury work flow -- Only for Accident Injured Option  */}
              {data.whatAreYouReportingFor === "ACCIDENT - Injury" && page === 2  ?
              <div>
                  {/*About the person who had the accident*/}

                  <div className="mb-5">
                      <h4 style={{ color : "#171635"}}><i className="bi bi-patch-question-fill"></i> About the person who had the Accident</h4>
                      <small>Please answer all questions as thoroughly and accurately as possible</small>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.injuredPersonsFullName !=="" && "success_valid" }
                          ${ isNextClicked === true && data.injuredPersonsFullName ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="injuredPersonsFullName"> <strong className="text-danger">*</strong> Injured Person's Full Name?</label>
                      <small>if known</small>
                      <input className="form-control" id="injuredPersonsFullName" value={data.injuredPersonsFullName} name={"injuredPersonsFullName"} type="text" placeholder="Enter your answer"/>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.injuredPersonsAddress !=="" && "success_valid" }
                          ${ isNextClicked === true && data.injuredPersonsAddress ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="injuredPersonsAddress"> <strong className="text-danger">*</strong> Address?</label>
                      <small>this is the usual home address of the injured person (if known)</small>
                      <input className="form-control" id="injuredPersonsAddress" name={"injuredPersonsAddress"} type="text" value={data.injuredPersonsAddress} placeholder="Enter your answer"/>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && "success_valid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="injuredPersonsPostcode"> {/*<strong className="text-danger">*</strong>*/} Postcode?</label>
                      <small>if known</small>
                      <input className="form-control" id="injuredPersonsPostcode" value={data.injuredPersonsPostcode} name={"injuredPersonsPostcode"} type="text" placeholder="Enter your answer"/>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.injuredPersonsAge !=="" && "success_valid" }
                          ${ isNextClicked === true && data.injuredPersonsAge ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>{
                          const name = e.target.name;
                          const value = e.target.value;
                          const reg = /^[0-9\b]+$/;
                          if(value === "" || reg.test(value))
                          setData(prev=>({ ...prev, [name]: value }));
                      }}
                  >
                      <label className="form-label" htmlFor="injuredPersonsAge"> <strong className="text-danger">*</strong> Age?</label>
                      <small>Answer must be a number (an approximate age is acceptable)</small>
                      <input className="form-control" id="injuredPersonsAge" name={"injuredPersonsAge"} value={data.injuredPersonsAge} type="text"  placeholder="Enter your answer"/>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.injuredPersonsInjuryType !== "" && "success_valid" }
                          ${ isNextClicked === true && data.injuredPersonsInjuryType === "" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="injuredPersonsInjuryType"> <strong className="text-danger">*</strong> What Injuries has the person suffered?</label>
                      <br/>
                      <small>Please be as accurate as possible (e.g. Burns, Cut, Bruising). Where necessary include suspected injuries such as head injury.</small>
                      <textarea className="form-control mt-3" id="injuredPersonsInjuryType" name={"injuredPersonsInjuryType"} value={data.injuredPersonsInjuryType} type="text" placeholder="Enter your answer" style={{height: "7rem"}}></textarea>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.injuredPersonsInjuryLocation !=="" && "success_valid" }
                          ${ isNextClicked === true && data.injuredPersonsInjuryLocation ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="injuredPersonsInjuryLocation"> <strong className="text-danger">*</strong> Where on the body are the injuries?</label>
                      <br/>
                      <small>Please include descriptors such as left, right, upper or lower where necessary)</small>
                      <textarea className="form-control mt-3" id="injuredPersonsInjuryLocation" name={"injuredPersonsInjuryLocation"} value={data.injuredPersonsInjuryLocation} type="text" placeholder="Enter your answer"
                                style={{height: "7rem"}} ></textarea>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.injuredPersonsFirstAid !=="" && "success_valid" }
                          ${ isNextClicked === true && data.injuredPersonsFirstAid ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="injuredPersonsFirstAid"> <strong className="text-danger">*</strong> What First Aid Treatment was given on the scene?</label>
                      <textarea className="form-control mt-3" id="injuredPersonsFirstAid" name={"injuredPersonsFirstAid"} value={data.injuredPersonsFirstAid}  type="text" placeholder="Enter your answer"
                                style={{height: "7rem"}} ></textarea>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.injuredPersonsPermission !=="" && "success_valid" }
                          ${ isNextClicked === true && data.injuredPersonsPermission ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label d-block">
                          <strong className="text-danger">*</strong>  Does the Injured Person give permission for personal and/or contact information to be passed on to the University?
                      </label>

                      <small>This will only happen for accident investigation purposes where the University are liable, or for student welfare and academic support purposes. No details will be shared with any 3rd parties unless required by law.</small>

                      <div className="form-check mt-3">
                          <input className="form-check-input" id="injuredPersonsPermissionYes" type="radio"
                                 name="injuredPersonsPermission"
                                 value={"YES"}
                                 checked={data.injuredPersonsPermission==="YES"}
                                 />
                          <label className="form-check-label" htmlFor="injuredPersonsPermissionYes">Yes</label>
                      </div>
                      <div className="form-check">
                          <input className="form-check-input" id="injuredPersonsPermissionNo" type="radio"
                                 name="injuredPersonsPermission"
                                 value={"NO"}
                                 checked={data.injuredPersonsPermission==="NO"}
                                 />
                          <label className="form-check-label" htmlFor="injuredPersonsPermissionNo">No</label>
                      </div>
                      <div className="form-check">
                          <input className="form-check-input" type="radio"
                                 name="injuredPersonsPermission"
                                 id={"injuredPersonsPermissionConsent"}
                                 value={"Consent could not be obtained at the time of the event"}
                                 checked={data.injuredPersonsPermission==="Consent could not be obtained at the time of the event"}
                                 />
                          <label className="form-check-label" htmlFor="injuredPersonsPermissionConsent">Consent could not be obtained at the time of the event</label>
                      </div>
                  </div>


              </div>
                  :
                  null
              }

              {/* Damage/Incident/Near-miss Reporting Work Flow */}

              {page === 3  ?
              <div>
                  {/*About the damage / Incident / Near Miss */}

                  <div className="mb-3">
                      <h4 style={{ color : "#171635"}}><i className="bi bi-question-square-fill"></i> About the <strong>{data.whatAreYouReportingFor}</strong></h4>
                      <small>Please read and answer all questions in this section carefully</small>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.didTheEventHappenOnAUniversityCampus !=="" && "success_valid" }
                          ${ isNextClicked === true && data.didTheEventHappenOnAUniversityCampus ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label d-block">
                          <strong className="text-danger">*</strong>  Did the {data.whatAreYouReportingFor} happen on a University Campus (or University owned property)?
                      </label>

                      <div className="form-check">
                          <input className="form-check-input"
                                 id="didTheEventHappenOnAUniversityCampusYes"
                                 type="radio"
                                 value={"YES"}
                                 name="didTheEventHappenOnAUniversityCampus"
                                 checked={data.didTheEventHappenOnAUniversityCampus==="YES"}
                          />
                          <label className="form-check-label" htmlFor="didTheEventHappenOnAUniversityCampusYes">Yes</label>
                      </div>
                      <div className="form-check">
                          <input className="form-check-input"
                                 id="didTheEventHappenOnAUniversityCampusNo"
                                 type="radio"
                                 value={"NO"}
                                 name="didTheEventHappenOnAUniversityCampus"
                                 checked={data.didTheEventHappenOnAUniversityCampus==="NO"}
                                 />
                          <label className="form-check-label" htmlFor="didTheEventHappenOnAUniversityCampusNo">No</label>
                      </div>
                      <div className="form-check">
                          <input className="form-check-input"
                                 id="didTheEventHappenOnAUniversityCampusUnsure"
                                 type="radio"
                                 value={"UNSURE"}
                                 checked={data.didTheEventHappenOnAUniversityCampus==="UNSURE"}
                                 name="didTheEventHappenOnAUniversityCampus"/>
                          <label className="form-check-label" htmlFor="didTheEventHappenOnAUniversityCampusUnsure">Unsure / Unknown</label>
                      </div>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.whereDidTheEventHappen !=="" && "success_valid" }
                          ${ isNextClicked === true && data.whereDidTheEventHappen ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="whereDidTheEventHappen"> <strong className="text-danger">*</strong> <strong>WHERE</strong> did the {data.whatAreYouReportingFor} Happen?</label>
                      <br/>
                      <small>Please include as much information as possible - such as address, Building name, room number, postcode. For very remote accidents an OS Grid Reference or "What 3 Words" location is acceptable.</small>
                      <textarea className="form-control mt-3" id="whereDidTheEventHappen" value={data.whereDidTheEventHappen} type="text" name="whereDidTheEventHappen" placeholder="Enter your answer" style={{height: "7rem"}}></textarea>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.whenDidTheAccidentHappen !=="" && "success_valid" }
                          ${ isNextClicked === true && data.whenDidTheAccidentHappen ==="" && "error_invalid" }
                          `}
                     // onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="whenDidTheAccidentHappen"> <strong className="text-danger">*</strong> <strong>WHEN</strong> did the {data.whatAreYouReportingFor} happen? </label>
                      <br/>
                      <DatePicker
                          selected={data.whenDidTheAccidentHappen}
                          onChange={(date) => {
                              setData(prev=>({ ...prev, "whenDidTheAccidentHappen": date }));
                          }}
                          timeInputLabel="Time:"
                          dateFormat="yyyy-dd-MM hh:mm aa"
                          showTimeInput
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          shouldCloseOnSelect={false}
                      />
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.pleaseDescribeHowTheEventHappened !=="" && "success_valid" }
                          ${ isNextClicked === true && data.pleaseDescribeHowTheEventHappened ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="pleaseDescribeHowTheEventHappened"> <strong className="text-danger">*</strong> Please describe what happened</label>
                      <br/>
                      <small>Please ensure this is clear and factual. You should not include elements of blame or wild speculation.</small>
                      <textarea className="form-control mt-3" id="pleaseDescribeHowTheEventHappened" name={"pleaseDescribeHowTheEventHappened"} value={data.pleaseDescribeHowTheEventHappened} type="text" placeholder="Enter your answer"
                                style={{height: "7rem"}} data-sb-validations="required"></textarea>
                  </div>

                  {data.whatAreYouReportingFor === "ACCIDENT - Injury" ?
                      <>
                          <div
                          className={`
                              mb-5 
                              ${ isNextClicked === true && data.afterTheInjuryDidAnyOfTheFollowingINJURY.length !==0 && "success_valid" }
                              ${ isNextClicked === true && data.afterTheInjuryDidAnyOfTheFollowingINJURY.length ===0 && "error_invalid" }
                              `}
                          onChange={(e)=>{
                              const value = e.target.value;
                              if(!data.afterTheInjuryDidAnyOfTheFollowingINJURY.includes(value)) {
                                  if(value==="NONE OF THE ABOVE"){
                                      setData(prev => ({
                                          ...prev,
                                          "afterTheInjuryDidAnyOfTheFollowingINJURY": [value]
                                      }));
                                  }else {
                                      setData(prev => ({
                                          ...prev,
                                          "afterTheInjuryDidAnyOfTheFollowingINJURY": [...data.afterTheInjuryDidAnyOfTheFollowingINJURY, value]
                                      }));
                                  }
                              }
                              else {
                                  setData(prev => ({
                                      ...prev,
                                      "afterTheInjuryDidAnyOfTheFollowingINJURY": [...data.afterTheInjuryDidAnyOfTheFollowingINJURY.filter(e=> e!== value)]
                                  }));
                              }
                          }}
                      >
                          <label className="form-label"><strong className="text-danger">*</strong> After the Injury Accident - Did any of the following occur?</label>
                          <br/>
                          <small>Please tick all that apply</small>
                          <div className="form-check mt-3">
                              <input checked={data.afterTheInjuryDidAnyOfTheFollowingINJURY.includes("Ambulance Called")} className="form-check-input" id="afterTheInjuryDidAnyOfTheFollowingINJURYA" type="checkbox" value={"Ambulance Called"} name="afterTheInjuryDidAnyOfTheFollowingINJURYA"/>
                              <label className="form-check-label" htmlFor="afterTheInjuryDidAnyOfTheFollowingINJURYA">Ambulance Called</label>
                          </div>
                          <div className="form-check">
                              <input checked={data.afterTheInjuryDidAnyOfTheFollowingINJURY.includes("Injured Person taken directly to hospital")} className="form-check-input" id="afterTheInjuryDidAnyOfTheFollowingINJURYB" type="checkbox" value={"Injured Person taken directly to hospital"} name="afterTheInjuryDidAnyOfTheFollowingINJURYB"/>
                              <label className="form-check-label" htmlFor="afterTheInjuryDidAnyOfTheFollowingINJURYB">Injured Person taken directly to hospital</label>
                          </div>
                          <div className="form-check">
                              <input checked={data.afterTheInjuryDidAnyOfTheFollowingINJURY.includes("Injured Person Advised to see a doctor")} className="form-check-input" id="afterTheInjuryDidAnyOfTheFollowingINJURYC" type="checkbox" value={"Injured Person Advised to see a doctor"} name="afterTheInjuryDidAnyOfTheFollowingINJURYC"/>
                              <label className="form-check-label" htmlFor="afterTheInjuryDidAnyOfTheFollowingINJURYC">Injured Person Advised to see a doctor</label>
                          </div>
                          <div className="form-check">
                              <input checked={data.afterTheInjuryDidAnyOfTheFollowingINJURY.includes("University Campus Security Called")} className="form-check-input" id="afterTheInjuryDidAnyOfTheFollowingINJURYD" type="checkbox" value={"University Campus Security Called"} name="afterTheInjuryDidAnyOfTheFollowingINJURYD"/>
                              <label className="form-check-label" htmlFor="afterTheInjuryDidAnyOfTheFollowingINJURYD">University Campus Security Called</label>
                          </div>
                          <div className="form-check">
                              <input checked={data.afterTheInjuryDidAnyOfTheFollowingINJURY.includes("Rescue Team Required (e.g. Mountain Rescue , Coastguard etc)")} className="form-check-input" id="afterTheInjuryDidAnyOfTheFollowingINJURYE" type="checkbox" value="Rescue Team Required (e.g. Mountain Rescue , Coastguard etc)" name="afterTheInjuryDidAnyOfTheFollowingINJURYE"/>
                              <label className="form-check-label" htmlFor="afterTheInjuryDidAnyOfTheFollowingINJURYE">Rescue Team Required (e.g. Mountain Rescue , Coastguard etc)</label>
                          </div>
                          <div className="form-check">
                              <input checked={data.afterTheInjuryDidAnyOfTheFollowingINJURY.includes("NONE OF THE ABOVE")} className="form-check-input" value="NONE OF THE ABOVE" id="afterTheInjuryDidAnyOfTheFollowingINJURYF" type="checkbox" name="afterTheInjuryDidAnyOfTheFollowingINJURYF"/>
                              <label className="form-check-label" htmlFor="afterTheInjuryDidAnyOfTheFollowingINJURYF">NONE OF THE ABOVE</label>
                          </div>
                      </div>


                          <div
                              className={`
                                  mb-5 
                                  ${ isNextClicked === true && data.whatCausedTheAccident !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.whatCausedTheAccident ==="" && "error_invalid" }
                                  `}
                              onChange={(e)=>handleChange(e)}
                          >
                              <label htmlFor="whatCausedTheAccident" className="form-label">
                                  <strong className="text-danger">*</strong> What caused the accident?
                              </label>
                              <br/>
                              <small>Please select the most appropriate option from this list</small>
                              <select value={data.whatCausedTheAccident} name={"whatCausedTheAccident"} className="form-select mt-3" defaultValue={""} id="whatCausedTheAccident" aria-label="What caused the accident?">
                                  <option value="">--Please select an option</option>
                                  <option value="Contact / Collision between People">Contact / Collision between People</option>
                                  <option value="Slip or Trip">Slip or Trip</option>
                                  <option value="Falls from height">Falls from height</option>
                                  <option value="Tool, Equipment or Machinery">Tool, Equipment or Machinery</option>
                                  <option value="Fire or Heat Contact">Fire or Heat Contact</option>
                                  <option value="Handling or Lifting">Handling or Lifting</option>
                                  <option value="Impact with a fixed / non-moving object">Impact with a fixed / non-moving object</option>
                                  <option value="Hit by a moving object (NOT Vehicle)">Hit by a moving object (NOT Vehicle)</option>
                                  <option value="Hit by a moving object">Hit by a moving object</option>
                                  <option value="Contact with Electricity">Contact with Electricity</option>
                                  <option value="Contact with a hazardous substance (e.g. Chemicals)">Contact with a hazardous substance (e.g. Chemicals)</option>
                                  <option value="Cold (hypothermia)">Cold (hypothermia)</option>
                                  <option value="Water Related (e.g submersion, inhalation of water)">Water Related (e.g submersion, inhalation of water)</option>
                                  <option value="Air or Water craft">Air or Water craft</option>
                                  <option value="Weapon">Weapon</option>
                                  <option value="Other">Other</option>
                              </select>
                          </div>

                          {data.whatCausedTheAccident === "Other" ?
                              <div
                                  className={`
                                   mb-5 
                                  ${ isNextClicked === true && data.whatCausedTheAccidentOtherReason !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.whatCausedTheAccidentOtherReason ==="" && "error_invalid" }
                                   `}
                                  onChange={(e)=>handleChange(e)}
                              >
                                  <label className="form-label" htmlFor="whatCausedTheAccidentOtherReason"> <strong className="text-danger">*</strong>Please state what caused the accident.</label>
                                  <input className="form-control" id="whatCausedTheAccidentOtherReason" value={data.whatCausedTheAccidentOtherReason} name={"whatCausedTheAccidentOtherReason"} type="text" placeholder="Enter your answer"/>
                              </div>
                          :
                          null
                          }

                          <div
                              className={`
                                  mb-5 
                                  ${ isNextClicked === true && data.doAnyFirstAidKitsNeedToBeRefilled !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.doAnyFirstAidKitsNeedToBeRefilled ==="" && "error_invalid" }
                                  `}
                              onChange={(e)=>handleChange(e)}
                          >
                              <label className="form-label">
                                  <strong className="text-danger">*</strong>  Do any first aid kits need to be refilled after treating this incident?
                              </label>

                              <br/>
                              <small>If you are a student group, please take your kit to the SU reception - where it can be refilled free of charge</small>

                              <div className="form-check mt-3">
                                  <input className="form-check-input" id="doAnyFirstAidKitsNeedToBeRefilledYes" type="radio"
                                         name="doAnyFirstAidKitsNeedToBeRefilled"
                                         value={"YES"}
                                         checked={data.doAnyFirstAidKitsNeedToBeRefilled==="YES"}
                                  />
                                  <label className="form-check-label" htmlFor="doAnyFirstAidKitsNeedToBeRefilledYes">Yes</label>
                              </div>
                              <div className="form-check">
                                  <input className="form-check-input" id="doAnyFirstAidKitsNeedToBeRefilledNo" type="radio"
                                         name="doAnyFirstAidKitsNeedToBeRefilled"
                                         value={"NO"}
                                         checked={data.doAnyFirstAidKitsNeedToBeRefilled==="NO"}
                                  />
                                  <label className="form-check-label" htmlFor="doAnyFirstAidKitsNeedToBeRefilledNo">No</label>
                              </div>
                              <div className="form-check">
                                  <input className="form-check-input" id="doAnyFirstAidKitsNeedToBeRefilledUnsure" type="radio"
                                         name="doAnyFirstAidKitsNeedToBeRefilled"
                                         value={"UNSURE"}
                                         checked={data.doAnyFirstAidKitsNeedToBeRefilled==="unsure"}
                                  />
                                  <label className="form-check-label" htmlFor="doAnyFirstAidKitsNeedToBeRefilledunsure">Unsure</label>
                              </div>
                          </div>

                  </>
                      :null
                  }

                  {data.whatAreYouReportingFor === "ACCIDENT - Damage" ?
                      <>
                          <div
                              className={`
                              mb-5 
                              ${ isNextClicked === true && data.whatHasBeenDamagedOrBroken.length !==0 && "success_valid" }
                              ${ isNextClicked === true && data.whatHasBeenDamagedOrBroken.length ===0 && "error_invalid" }
                              `}
                              onChange={(e)=>{
                                  const value = e.target.value;
                                  if(!data.whatHasBeenDamagedOrBroken.includes(value)) {
                                          setData(prev => ({
                                              ...prev,
                                              "whatHasBeenDamagedOrBroken": [...data.whatHasBeenDamagedOrBroken, value]
                                          }));
                                  }
                                  else {
                                      setData(prev => ({
                                          ...prev,
                                          "whatHasBeenDamagedOrBroken": [...data.whatHasBeenDamagedOrBroken.filter(e=> e!== value)]
                                      }));
                                  }
                              }}
                          >
                              <label className="form-label"><strong className="text-danger">*</strong> What has been damaged or broken?</label>
                              <br/>
                              <small>Please tick all that apply</small>
                              <div className="form-check mt-3">
                                  <input checked={data.whatHasBeenDamagedOrBroken.includes("Equipment, Tools or Machinery (NOT Vehicle)")} value="Equipment, Tools or Machinery (NOT Vehicle)" className="form-check-input" id="whatHasBeenDamagedOrBrokenB" type="checkbox"  name="whatHasBeenDamagedOrBrokenB"/>
                                  <label className="form-check-label" htmlFor="whatHasBeenDamagedOrBrokenB">Equipment, Tools or Machinery (NOT Vehicle)</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.whatHasBeenDamagedOrBroken.includes("Property or Buildings - UNIVERSITY OWNED")} value="Property or Buildings - UNIVERSITY OWNED" className="form-check-input" id="whatHasBeenDamagedOrBrokenC" type="checkbox"  name="whatHasBeenDamagedOrBrokenC"/>
                                  <label className="form-check-label" htmlFor="whatHasBeenDamagedOrBrokenC">Property or Buildings - UNIVERSITY OWNED</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.whatHasBeenDamagedOrBroken.includes("Property or Buildings - OTHER")} value="Property or Buildings - OTHER" className="form-check-input" id="whatHasBeenDamagedOrBrokenD" type="checkbox" name="whatHasBeenDamagedOrBrokenD"/>
                                  <label className="form-check-label" htmlFor="whatHasBeenDamagedOrBrokenD">Property or Buildings - OTHER</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.whatHasBeenDamagedOrBroken.includes("Vehicle")} className="form-check-input" value="Vehicle" id="whatHasBeenDamagedOrBrokenE" type="checkbox" name="whatHasBeenDamagedOrBrokenE"/>
                                  <label className="form-check-label" htmlFor="whatHasBeenDamagedOrBrokenE">Vehicle</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.whatHasBeenDamagedOrBroken.includes("Other")} className="form-check-input" value="Other" id="whatHasBeenDamagedOrBrokenF" type="checkbox" name="whatHasBeenDamagedOrBrokenF"/>
                                  <label className="form-check-label" htmlFor="whatHasBeenDamagedOrBrokenF">Other</label>
                              </div>
                          </div>
                      </>
                      :
                      null
                  }

                  {
                      data.whatAreYouReportingFor === "ACCIDENT - Damage" && data.whatHasBeenDamagedOrBroken.length>0 ?
                          <div
                              className={`
                          mb-5 
                          ${ isNextClicked === true && data.whatHasBeenDamagedOrBrokenDescription !=="" && "success_valid" }
                          ${ isNextClicked === true && data.whatHasBeenDamagedOrBrokenDescription ==="" && "error_invalid" }
                          `}
                              onChange={(e)=>handleChange(e)}
                          >
                              <label className="form-label" htmlFor="whatHasBeenDamagedOrBrokenDescription"> <strong className="text-danger">*</strong>Please describe what has been damaged or broken</label>
                              <br/>
                              <small>Please ensure this is clear and factual. You should not include elements of blame or wild speculation.</small>
                              <textarea className="form-control mt-3" id="whatHasBeenDamagedOrBrokenDescription" name={"whatHasBeenDamagedOrBrokenDescription"} value={data.whatHasBeenDamagedOrBrokenDescription} type="text" placeholder="Enter response"
                                        style={{height: "7rem"}} data-sb-validations="required"></textarea>
                          </div>
                          :
                          null
                  }

                  {data.whatAreYouReportingFor === "ACCIDENT - Damage" || data.whatAreYouReportingFor === "ACCIDENT - Injury" ?
                      <>
                          <div
                              /* style={{display: "none"}}*/
                              className={`
                              mb-5 
                              ${ isNextClicked === true && data.afterTheEventDidAnyOfTheFollowingOthers.length !==0 && "success_valid" }
                              ${ isNextClicked === true && data.afterTheEventDidAnyOfTheFollowingOthers.length ===0 && "error_invalid" }
                              `}
                              onChange={(e)=>{
                                  const value = e.target.value;
                                  if(!data.afterTheEventDidAnyOfTheFollowingOthers.includes(value)) {
                                      if(value==="NONE OF THE ABOVE"){
                                          setData(prev => ({
                                              ...prev,
                                              "afterTheEventDidAnyOfTheFollowingOthers": [value]
                                          }));
                                      }else {
                                          setData(prev => ({
                                              ...prev,
                                              "afterTheEventDidAnyOfTheFollowingOthers": [...data.afterTheEventDidAnyOfTheFollowingOthers, value]
                                          }));
                                      }
                                  }
                                  else {
                                      setData(prev => ({
                                          ...prev,
                                          "afterTheEventDidAnyOfTheFollowingOthers": [...data.afterTheEventDidAnyOfTheFollowingOthers.filter(e=> e!== value)]
                                      }));
                                  }
                              }}
                          >
                              <label className="form-label"><strong className="text-danger">*</strong> After the {data.whatAreYouReportingFor}  - Did any of the following occur?</label>
                              <br/>
                              <small>Please tick all that apply</small>
                              <div className="form-check mt-3">
                                  <input checked={data.afterTheEventDidAnyOfTheFollowingOthers.includes("UoN Campus Security Called")} className="form-check-input" id="afterTheEventDidAnyOfTheFollowingOthersG" type="checkbox" value={"UoN Campus Security Called"} name="afterTheEventDidAnyOfTheFollowingOthersG"/>
                                  <label className="form-check-label" htmlFor="afterTheEventDidAnyOfTheFollowingOthersG">UoN Campus Security Called</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.afterTheEventDidAnyOfTheFollowingOthers.includes("Police Called")} className="form-check-input" id="afterTheEventDidAnyOfTheFollowingOthersA" type="checkbox" value={"Police Called"} name="afterTheEventDidAnyOfTheFollowingOthersA"/>
                                  <label className="form-check-label" htmlFor="afterTheEventDidAnyOfTheFollowingOthersA">Police Called</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.afterTheEventDidAnyOfTheFollowingOthers.includes("Fire and Rescue Service Called")} className="form-check-input" id="afterTheEventDidAnyOfTheFollowingOthersB" type="checkbox" value={"Fire and Rescue Service Called"} name="afterTheEventDidAnyOfTheFollowingOthersB"/>
                                  <label className="form-check-label" htmlFor="afterTheEventDidAnyOfTheFollowingOthersB">Fire and Rescue Service Called</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.afterTheEventDidAnyOfTheFollowingOthers.includes("Utility Company Emergency Line Called (e.g. gas, water, electric)")} className="form-check-input" id="afterTheEventDidAnyOfTheFollowingOthersC" type="checkbox" value={"Utility Company Emergency Line Called (e.g. gas, water, electric)"} name="afterTheInjuryDidAnyOfTheFollowingINJURYC"/>
                                  <label className="form-check-label" htmlFor="afterTheEventDidAnyOfTheFollowingOthersC">Utility Company Emergency Line Called (e.g. gas, water, electric)</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.afterTheEventDidAnyOfTheFollowingOthers.includes("Other Emergency Services required (e.g. mountain rescue)")} className="form-check-input" id="afterTheEventDidAnyOfTheFollowingOthersD" type="checkbox" value={"Other Emergency Services required (e.g. mountain rescue)"} name="afterTheEventDidAnyOfTheFollowingOthersD"/>
                                  <label className="form-check-label" htmlFor="afterTheEventDidAnyOfTheFollowingOthersD">Other Emergency Services required (e.g. mountain rescue)</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.afterTheEventDidAnyOfTheFollowingOthers.includes("Other Specialist Required (e.g. Structural Engineer)")} className="form-check-input" id="afterTheEventDidAnyOfTheFollowingOthersE" type="checkbox" value="Other Specialist Required (e.g. Structural Engineer)" name="afterTheEventDidAnyOfTheFollowingOthersE"/>
                                  <label className="form-check-label" htmlFor="afterTheEventDidAnyOfTheFollowingOthersE">Other Specialist Required (e.g. Structural Engineer)</label>
                              </div>
                              <div className="form-check">
                                  <input checked={data.afterTheEventDidAnyOfTheFollowingOthers.includes("NONE OF THE ABOVE")} className="form-check-input" value="NONE OF THE ABOVE" id="afterTheEventDidAnyOfTheFollowingOthersF" type="checkbox" name="afterTheEventDidAnyOfTheFollowingOthersF"/>
                                  <label className="form-check-label" htmlFor="afterTheEventDidAnyOfTheFollowingOthersF">NONE OF THE ABOVE</label>
                              </div>
                          </div>

                      </>
                      :null
                  }

              </div>
                  :
                  null
              }





              {/* Witnesses Section */}
              {page === 4  ?
              <div>

                  <div className="mb-5">
                      <h4 style={{ color : "#171635"}}><i className="bi bi-people-fill"></i> Witnesses</h4>
                      <small>You can use this section to record any key witnesses who saw the Accident/Incident or Near-Miss</small>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.didAnybodyElseWitnessTheEvent !=="" && "success_valid" }
                          ${ isNextClicked === true && data.didAnybodyElseWitnessTheEvent ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label">
                          <strong className="text-danger">*</strong> Did Anybody Else Witness the Accident/Incident or Near-miss?
                      </label>

                      <div className="form-check">
                          <input className="form-check-input" id="didAnybodyElseWitnessTheEventA" type="radio"
                                 checked={data.didAnybodyElseWitnessTheEvent === "YES"}
                                 name="didAnybodyElseWitnessTheEvent"
                                 value="YES"
                                 />
                          <label className="form-check-label" htmlFor="didAnybodyElseWitnessTheEventA">Yes</label>
                      </div>
                      <div className="form-check">
                          <input className="form-check-input" id="didAnybodyElseWitnessTheEventB" type="radio"
                                 name="didAnybodyElseWitnessTheEvent"
                                 checked={data.didAnybodyElseWitnessTheEvent === "NO"}
                                 value="NO"
                                 />
                          <label className="form-check-label" htmlFor="didAnybodyElseWitnessTheEventB">No</label>
                      </div>

                  </div>

                  {data.didAnybodyElseWitnessTheEvent === "YES" ?

                       <>
                          <div
                              className={`
                                  mb-5 
                                  ${ isNextClicked === true && data.witness1Name !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.witness1Name ==="" && "error_invalid" }
                                  `}
                              onChange={(e)=>handleChange(e)}
                          >
                              <label className="form-label" htmlFor="witness1Name"> <strong className="text-danger">*</strong> Witness #1 Name: </label>
                              <input className="form-control" id="witness1Name" name="witness1Name" value={data.witness1Name} type="text" placeholder="Enter your answer" />
                          </div>

                          <div
                              className={`
                                  mb-5 
                                  ${ isNextClicked === true && data.witness1Contact !=="" && "success_valid" }
                                  ${ isNextClicked === true && data.witness1Contact ==="" && "error_invalid" }
                                  `}
                              onChange={(e)=>handleChange(e)}
                          >
                              <label className="form-label" htmlFor="witness1Contact"> <strong className="text-danger">*</strong> Witness #1  Contact Details:</label>
                              <br/>
                              <small>E-mail, Phone Number or Postal Address are all acceptable.</small>
                              <textarea className="form-control" id="witness1Contact" name="witness1Contact" value={data.witness1Contact} type="text" placeholder="Enter your answer"
                                        style={{height: "7rem"}}></textarea>
                          </div>

                          <div className="mb-5" onChange={(e)=>handleChange(e)}>
                              <label className="form-label" htmlFor="witness2Name"> Witness #2 Name: </label>
                              <input className="form-control" id="witness2Name" name="witness2Name" value={data.witness2Name} type="text" placeholder="Enter your answer" />
                          </div>

                          <div className="mb-5" onChange={(e)=>handleChange(e)}>
                              <label className="form-label" htmlFor="witness2Contact"> Witness #2 Contact Details:</label>
                              <br/>
                              <small>E-mail, Phone Number or Postal Address are all acceptable.</small>
                              <textarea className="form-control" id="witness2Contact" name="witness2Contact" value={data.witness2Contact} type="text" placeholder="Enter your answer"
                                        style={{height: "7rem"}}></textarea>
                          </div>

                          <div className="mb-5" onChange={(e)=>handleChange(e)}>
                              <label className="form-label" htmlFor="additionalWitnesses"> Add details of any additional witnesses below:</label>
                              <textarea className="form-control" id="additionalWitnesses" name="additionalWitnesses" value={data.additionalWitnesses} type="text" placeholder="Enter your answer"
                                        style={{height: "7rem"}}></textarea>
                          </div>
                       </>
                              :
                        null
                  }

              </div>
                  :
                  null
              }

              {/*Form filled by*/}
              {page === 5  ?
              <div>

                  <div className="mb-5">
                      <h4 style={{ color : "#171635"}}><i className="bi bi-person-vcard"></i> About the Person Completing this Form</h4>
                      <small>Please answer all questions as accurately as possible - your information will not be passed to any 3rd party unless required by law or for further information if an accident investigation is conducted.</small>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.fillerFullName !=="" && "success_valid" }
                          ${ isNextClicked === true && data.fillerFullName ==="" && "error_invalid" }
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="fillerFullName"> <strong className="text-danger">*</strong> Your Full Name: </label>
                      <input className="form-control" id="fillerFullName" name="fillerFullName" value={data.fillerFullName} type="text" placeholder="Enter your answer"/>
                  </div>

                  <div
                      className={`
                          mb-5 
                          ${ isNextClicked === true && data.fillerEmailAddress !=="" && "success_valid" }
                          ${ isNextClicked === true && data.fillerEmailAddress ==="" && "error_invalid" }
                          ${ isNextClicked === true && !( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(data.fillerEmailAddress) && "error_invalid"}
                          
                          `}
                      onChange={(e)=>handleChange(e)}
                  >
                      <label className="form-label" htmlFor="fillerEmail"> <strong className="text-danger">*</strong> Your Email: </label>
                      <input className="form-control" id="fillerEmail" name="fillerEmailAddress" value={data.fillerEmailAddress} type="email" placeholder="Enter your answer"/>
                  </div>

                  <div className="mb-5" onChange={(e)=>handleChange(e)}>
                      <label className="form-label" htmlFor="fillerAdditionalContact">Any Other Additional Contact Details: </label>
                      <textarea className="form-control" id="fillerAdditionalContact" name="fillerAdditionalContact" value={data.fillerAdditionalContact} type="text" placeholder="Enter your answer"
                                style={{height: "7rem"}}></textarea>
                  </div>

                  <div style={{display : "flex", flexDirection : "column" , justifyContent : "center" , alignItems : "center" , paddingBottom : "30px"}}>
                      <ReCAPTCHA sitekey={RE_CAPTCHA_PUBLIC_KEY} onChange={(val)=> setData(prev=>({ ...prev, ["captchaVal"] : val }))}/>
                  </div>

              </div>
                  :
                  null
              }


              <div className="text-center">

                  {page !== 1  ? <button onClick={()=>handleBack()} className="btn btn-github btn-lg m-3"  type="button"> <i className="bi bi-arrow-left"></i> Back  </button> : null}

                  {page !== 5  ? <button onClick={()=>handleErrorAndSetNextPage()} className="btn btn-github btn-lg m-3" type="button">  Next <i className="bi bi-arrow-right"></i></button> : null }

                  {page === 5  ? <button disabled={!data?.captchaVal} onClick={()=>handleSubmit()} className="btn btn-success btn-lg  m-3"  type="button">  Submit <i className="bi bi-check-square"></i></button> : null}

              </div>



          </div>
            }


      </div>

    </div>
  );
}

export default AccidentReport;
