import React,{useEffect, useState} from "react";
import {Routes, Route,useLocation} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {ToastContainer, toast} from "react-toastify";
import {SET_TRIGGER_ALERT, SET_PASSWORD_CHANGE_REQUIRED} from "./redux/slice/authSlice";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingLines from "../src/assets/lottie/loading_lines.json";
import passwordChange from "../src/assets/lottie/passwordChange.json";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import useAxios from "./utils/hooks/useAxios";
import "./App.css";

//pages
import {
    /*Accident Report*/
    AccidentReport,
    /*Admin*/
    AdminLayout,Login,AdminDashboard,

    //Accident Report Admin
    AccidentsReportManager,SUGroupManager,NewSUGroup,EditSUGroup,

    //users
    UserManager,NewUser,EditUser,ViewUser,

    //Designation
    DesignationManager,NewDesignation,EditDesignation,/*ViewDesignation,*/

    //Roles
    RoleManager,NewRole,EditRole,PermissionManager,NewPermission,EditPermission,PermissionsCategoryManager,NewPermissionsCategory,EditPermissionsCategory,

    //Menu Management
    MenuManager,NewMenu,EditMenu,

    //Settings Management
    SettingsManager,NewSettings,EditSettings,

    /*404*/
    NotFound
} from "./pages"
import PrivateRoutes from "./utils/PrivateRoutes";
import PersistSession from "./utils/PersistSession";
import "react-toastify/dist/ReactToastify.css";


function App() {
    const location = useLocation();
    const {httpRequest}  = useAxios();
    const defaultForm = { password: "",passwordConfirm: ""};
    const [form, setForm] = useState(defaultForm);

    const {isLoading,triggerAlert, triggerAlertType, triggerAlertMessage, passwordChangeRequired} = useSelector((state)=>state.auth);
    const dispatch = useDispatch();
    let clearIT;
    useEffect(()=>{
            if(triggerAlert === true){
                let Msg = () => {
                    return (
                        <>
                            {parse(DOMPurify.sanitize(triggerAlertMessage))}
                        </>
                    );
                };

                if(triggerAlertType==='error'){
                    toast.error(<Msg/>);
                }

                if(triggerAlertType==='success'){
                    toast.success(<Msg/>);
                }

                clearIT = setTimeout(()=>{
                    dispatch(SET_TRIGGER_ALERT(false));
                },10000);
            }
            return ()=>{
                clearTimeout(clearIT);
            }
        },
        [triggerAlert]
    );

    //autoscroll back to top
    useEffect(() => {
        if(document.querySelector("#anchorScrollToElement"))
            document.querySelector("#anchorScrollToElement").scrollIntoView();
    }, [location]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm(prev=>({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        //e.preventDefault();

        //validation
        if(form.password !== form.passwordConfirm ){
            toast.error("Passwords must match");
            return;
        }

            await httpRequest('password/force/change','PATCH',form)
                .then((data) =>{
                    if(data){
                        toast.success("Operation completed successfully");
                        //Update only passChange state
                        dispatch(SET_PASSWORD_CHANGE_REQUIRED(false));
                    }
                });
    };


    return (
        <>
            <ToastContainer position="top-center" limit={1} autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="dark"/>

            <div className="preloader" style={{ display: isLoading ? "" : "none" }}>
                <Player
                    autoplay
                    loop
                    style={{ height: "100vh", width: "400px", position: "relative" }}
                    src={loadingLines}
                />
            </div>

            {
                passwordChangeRequired === true ?
            <div style={{
                margin: "0 auto",
                width: "100%",
                maxWidth:"800px",
                zIndex: "99999",
                padding: "20px"

            }}>
                <Player
                    autoplay
                    loop
                    style={{  width: "250px", position: "relative" }}
                    src={passwordChange}
                />
                <div className="row">
                    <div className="card card-plain">
                        <div className="card-header text-center">
                            <h4 className="font-weight-bolder">
                                Change Password
                            </h4>
                            <p className="mb-0">
                                Password change is required
                            </p>
                        </div>
                        <div className="card-body mt-2">
                            <div className="form-floating mb-3">
                                <input
                                    name="password"
                                    type="password"
                                    className={`form-control`}
                                    id="password"
                                    onChange={(e)=>handleChange(e)}
                                />
                                <label htmlFor="password">Password</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    name="passwordConfirm"
                                    type="password"
                                    className={`form-control`}
                                    id="passwordConfirm"
                                    onChange={(e)=>handleChange(e)}
                                />
                                <label htmlFor="passwordConfirm">Password Confirm</label>
                            </div>


                            <div className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-github btn-lg w-100 mt-4 mb-0"
                                    onClick={()=>handleSubmit()}
                                >
                                    CHANGE PASSWORD
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>:
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/accident-report" element={<AccidentReport />} />
                {/* <Route path="/explore/:cat/:subcat" element={<Explore  />} /> */}
                <Route element={<PersistSession />} >
                    <Route element={<PrivateRoutes />}>
                        <Route path="admin" element={<AdminLayout />}>
                            <Route path="dashboard" element={<AdminDashboard />} />

                            <Route path="accident-report">
                                <Route index element={<AccidentsReportManager />} />
                                <Route path="department" element={<SUGroupManager />} />
                                <Route path="department/new" element={<NewSUGroup />} />
                                <Route path="department/edit" element={<EditSUGroup />} />
                            </Route>

                            <Route path="manage/user">
                                <Route index element={<UserManager />} />
                                <Route path="new" element={<NewUser />} />
                                <Route path="edit" element={<EditUser />} />
                                <Route path="view" element={<ViewUser />} />
                            </Route>

                            <Route path="manage/menu">
                                <Route index element={<MenuManager />} />
                                <Route path="new" element={<NewMenu />} />
                                <Route path="edit" element={<EditMenu />} />
                            </Route>

                            <Route path="manage/designation">
                                <Route index element={<DesignationManager />} />
                                <Route path="new" element={<NewDesignation />} />
                                <Route path="edit" element={<EditDesignation />} />
                                {/*<Route path="view" element={<ViewDesignation />} />*/}
                            </Route>

                            <Route path="manage/role">
                                <Route index element={<RoleManager/>} />
                                <Route path="new" element={<NewRole />} />
                                <Route path="edit" element={<EditRole />} />
                                <Route path="permissions" element={<PermissionManager />} />
                                <Route path="permissions/create" element={<NewPermission />} />
                                <Route path="permissions/edit" element={<EditPermission />} />
                                <Route path="permissions/category" element={<PermissionsCategoryManager />} />
                                <Route path="permissions/category/create" element={<NewPermissionsCategory />} />
                                <Route path="permissions/category/edit" element={<EditPermissionsCategory />} />
                            </Route>

                            <Route path="manage/setting">
                                <Route index element={<SettingsManager />} />
                                <Route path="new" element={<NewSettings />} />
                                <Route path="edit" element={<EditSettings />} />
                            </Route>

                        </Route>
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
            }
        </>
    );
}

export default App;
