import React from 'react'
import { Link } from "react-router-dom";
import {APP_NAME} from "../../utils/Constants";
//import "../../assets/admin/css/material-dashboard.css";
const NotFound = () => {
  return (
		<main className="main-content  mt-0">
			<div
				className="page-header align-items-start min-vh-100"
				style={{
					backgroundImage:
						'url("https://images.unsplash.com/uploads/1413399939678471ea070/2c0343f7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
				}}
			>
				<span className="mask bg-gradient-dark opacity-6" />
				<div className="container my-auto">
					<div className="row">
						<div className="col-lg-12 m-auto text-center">
							<img style={{ borderRadius : "15px"}} src={require("../../uonsu_logo.png")} className="img-fluid animate__animated animate__pulse" alt="UoNSU Logo"/>
							<h1 className="display-1 text-bolder text-white">{APP_NAME}</h1>
							<h2 className="text-white">Erm. Page not found</h2>
							<p className="lead text-white">
								We suggest you to go to the homepage.
							</p>
              <Link to="/admin/dashboard">
							<button type="button" className="btn btn-outline-white mt-4">
								Go to Homepage
							</button></Link>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default NotFound