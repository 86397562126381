import React,{useEffect} from 'react';
import { useSelector } from "react-redux";

const Dashboard = () => {
	const state = useSelector((state) => state.auth);
  return (
		<div className="container-fluid py-4">
			<h6>Welcome. Kindly use the navigation on the left to maximize your experience</h6>
		</div>
	);
}

export default Dashboard