import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  name:"",
  designation: null,
  permissions:[],
  menu:[],
  subMenu:[],
  accessToken:null,
  isAuthenticated: false,
  isLoading:false,
  triggerAlert:false,
  triggerAlertType:'',//error or success
  triggerAlertMessage:'',
  passwordChangeRequired:false
}

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		SET_ACTIVE_USER: (state, { payload }) => {
			//return { ...action.payload, isLoading: false };
			state.name = payload.name;
			state.designation = payload.designation;
			state.permissions = payload.permissions;
			state.menu = payload.menu;
			state.subMenu = payload.subMenu;
			state.accessToken = payload.accessToken;
			state.isAuthenticated = payload.isAuthenticated;
			state.passwordChangeRequired = payload.passwordChangeRequired;
		},
		SET_ACCESS_TOKEN: (state, action) => {
			state.accessToken = action.payload;
		},
		SET_PASSWORD_CHANGE_REQUIRED: (state, action) => {
			state.passwordChangeRequired = action.payload;
		},
		CHANGE_LOADING_STATE: (state, action) => {
			state.isLoading = action.payload;
		},
		SET_TRIGGER_ALERT: (state, action) => {
			state.triggerAlert = action.payload;
		},
		SET_TRIGGER_ALERT_TYPE: (state, action) => {
			state.triggerAlertType = action.payload;
		},
		SET_TRIGGER_ALERT_MESSAGE: (state, action) => {
			state.triggerAlertMessage = action.payload;
		},
		LOGOUT: (state) => {
			//return initialState;  -->preserving lat and lng coordinates
			state.name = "";
			state.designation = null;
			state.permissions = [];
			state.menu = [];
			state.subMenu = [];
			state.accessToken = null;
			state.isAuthenticated = false;
			state.isLoading = false;
			state.triggerAlert = false;
			state.triggerAlertType = "";
			state.triggerAlertMessage = "";
			state.passwordChangeRequired=false;
		}
	},
});

export const {  SET_ACTIVE_USER , SET_TRIGGER_ALERT,
	            SET_TRIGGER_ALERT_TYPE, SET_TRIGGER_ALERT_MESSAGE, CHANGE_LOADING_STATE,
				LOGOUT, SET_ACCESS_TOKEN,SET_PASSWORD_CHANGE_REQUIRED } = authSlice.actions;

export default authSlice.reducer;