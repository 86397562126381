import {useDispatch} from "react-redux";
import {SET_ACCESS_TOKEN} from "../../redux/slice/authSlice";
import axios from "../../api/axios";
import {REQUEST_METHOD} from "../Constants";

const useRefreshToken = () => {
    const dispatch = useDispatch();
	const refreshAccessToken = async () => {
		try {
			const response = await axios({
				method: REQUEST_METHOD.GET,
				url: "/keepalive",
			});
			const accessToken = response.data?.data?.auth?.accessToken;
			dispatch(SET_ACCESS_TOKEN(accessToken));//update with access token
			return accessToken;//return to original query to continue request
		}
		catch (err) {
			return null;
		}
	};

	 return {refreshAccessToken}
}

export default useRefreshToken