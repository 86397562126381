import React, {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import {Link, useNavigate} from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";

import "react-datetime/css/react-datetime.css";
import {REQUEST_METHOD} from "../../../utils/Constants";

const SUGroupManager = () => {


    const [allData, setAllData] = useState([]);
	const {httpRequest}  = useAxios();
	const navigate = useNavigate();

	const handleSoftDelete = async (id,status) => {
		httpRequest(`accident_reports/manage/su_department/activate/deactivate`,REQUEST_METHOD.PATCH,{id,status}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.departmentId === id ? {...data,softDeleted: status} : data;
				});
				setAllData(newAllData);
			}
		});
	}


	const loadData = async () => {

			httpRequest(`accident_reports/manage/su_department`,'GET').then((data) =>{
				if (data?.data?.departments) {
					setAllData(data.data.departments);
				}
			});

	}


	useEffect(()=>{
		loadData();	
	},[]);

	return <>
			<div className="row mt-4 p-4">
					<div className="col-12">
						{/*<button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-github m-2">
							<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
							<span className="btn-inner--text">GO BACK</span>
						</button>*/}
						<AuthorizedAreas permission="CAN_SUB_MENU_ACCIDENT_REPORT_MANAGE_SU_DEPARTMENTS">
							<Link to="new">
								<button type="button" className="btn btn-icon btn-github m-2">
									<span className="btn-inner--icon">
										<i className="material-icons">add_box</i>
									</span>
									<span className="btn-inner--text">&nbsp;CREATE DEPARTMENT</span>
								</button>
							</Link>
						</AuthorizedAreas>

						<div className="card mb-4">

							<div className="card-header pb-0">
								<h6>Manage SU Departments</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_SUB_MENU_ACCIDENT_REPORT_MANAGE_SU_DEPARTMENTS">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													 <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Department
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Status
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.departmentId}`}>
															 <td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			 {s.departmentName }
																		</h6>
																	</div>
																</div>
															</td>


															<td>
																<p className="font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-outline-${s.softDeleted === 1 ? "warning" : "success"}`}>
																	{s.softDeleted === 1 ? "DISABLED" : "ACTIVE"}
																	</span>
																</p>
															</td>

															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-github dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_EDIT_SU_DEPARTMENTS">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={"edit"}
																					state={{
																						id: s.departmentId,
																						name: s.departmentName,
																				}}
																				>
																					EDIT DEPARTMENT
																				</Link>
																			</li>
																		</AuthorizedAreas>


																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_ACTIVATE_DEACTIVATE_SU_DEPARTMENTS">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleSoftDelete(s.departmentId,s.softDeleted === 1 ? 2 : 1)}
																				>
																					{s.softDeleted === 1 ? "ACTIVATE" : "DE-ACTIVATE"} DEPARTMENT
																				</Link>
																			</li>
																		</AuthorizedAreas>

																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
										</table>
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>
		</>

}

export default SUGroupManager