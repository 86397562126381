import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import {useLocation, useNavigate} from "react-router-dom";
import { PermissionCreateValidation } from "../../../utils/validations/PermissionCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import {REQUEST_METHOD} from "../../../utils/Constants";

  const EditPermission = () => {

      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const cat_id = location?.state?.cat_id;

      const defaultForm = {
        permissionId : id,
        permissionName: name,
        permissionCategoryId:cat_id,
        permissionDescription: ""
      };



      const [form, setForm] = useState(defaultForm);
      const [permissionCategories,setPermissionCategories] = useState([]);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {httpRequest}  = useAxios();

      const getInitializationData = async () =>{
          await httpRequest(`role/permissions/edit/initialize/${form.permissionId}`,REQUEST_METHOD.GET)
              .then((data) =>{
                  if(data){
                      setPermissionCategories(data.data.permissionsCategory);
                      setForm(prevState => ({...prevState,"permissionDescription":data.data.permission?.permissionDescription}));
                  }
              });
      }

      useEffect(() => {
          getInitializationData();
      },[]);

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      }

      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            PermissionCreateValidation.validate(form, { abortEarly: false }).then(async (values)=>{
                await httpRequest('role/permissions/save/edit','PATCH',form)
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Saved successfully");
                        setTimeout(()=>{navigate(-1);},1000);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
      };
      
	
	return <AuthorizedAreas permission="CAN_ADMINISTRATION_ROLE_PERMISSIONS_EDIT">
            <div className="card-body px-0 pt-0 pb-2">      
                    <form onSubmit={(e)=>handleSubmit(e)}>
                      <div style={{ padding: 20 }}>
                      <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-github">
                          <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                          <span className="btn-inner--text">GO BACK</span>
                        </button>
                        <div className="card mt-4" id="basic-info">
                          <div className="card-header"><h5>Permission Information</h5></div>
                          <div className="card-body pt-0">


                            <div className="row">
                                  <div className="col-12">
                                    <div className="input-group input-group-static">
                                      <label htmlFor="permissionName">Permission Name</label>
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        name="permissionName"
                                        id="permissionName"
                                        className="form-control"
                                        placeholder="Designation Name"
                                        value={form.permissionName}
                                        onChange={(e)=>handleChange(e)}
                                      />
                                    </div>
                                  </div>
                            </div>

                              <div className="row mt-4">
                                  <div className="col-12">
                                      <div className="input-group input-group-static">
                                          <label htmlFor="permissionDescription">Permission Description</label>
                                          <input
                                              autoComplete="off"
                                              type="text"
                                              name="permissionDescription"
                                              id="permissionDescription"
                                              className="form-control"
                                              placeholder="Describe permission e.g Authorise the holder to..."
                                              value={form.permissionDescription}
                                              onChange={(e)=>handleChange(e)}
                                          />
                                      </div>
                                  </div>
                              </div>

                              <div className="row mt-4">
                                  <div className="col-12">
                                      <div className="input-group input-group-static">
                                          <label htmlFor="permissionCategoryId">Permission Category</label>
                                          <select
                                              className="form-control"
                                              name="permissionCategoryId"
                                              id="permissionCategoryId"
                                              autoComplete="off"
                                              defaultValue={""}
                                              value={form.permissionCategoryId}
                                              onChange={e=>handleChange(e)}
                                          >
                                              <option value=""> --Select Permission Category--</option>
                                              { permissionCategories?.length ?
                                                  permissionCategories.map((p) => (
                                                  <option selected={p.permissionCategoryId === form.permissionCategoryId} value={p.permissionCategoryId}>{p.permissionCategory}</option>
                                              )) : null
                                              }
                                          </select>
                                      </div>
                                  </div>
                              </div>

                            <button type="submit" className="mt-5 btn btn-icon btn-github btn-lg w-100">
                                <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                                <span className="btn-inner--text"> SAVE EDITS</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> 
		</AuthorizedAreas>;
}

export default EditPermission